import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UserSettingsState {
  userSettingToggle: boolean;
}

const initialState: UserSettingsState = {
    userSettingToggle: false,
};

const userSettingsSlice = createSlice({
  name: 'admin-settings',
  initialState,
  reducers: {
    updateUserSettingsToggle: (state, action: PayloadAction<boolean>) => {
      state.userSettingToggle = action.payload;
    },
  }
});

export const { updateUserSettingsToggle } = userSettingsSlice.actions;
export default userSettingsSlice.reducer;
