import { USER_SETTINGS } from "api/endpoints/authEndpoints"
import { APP_FEATURE_ENVIRONMENT, AUTH_USER_CONFIGURATION, NOTIFY_OWNER_PAYMENT } from "api/endpoints/layoutEndpoints"
import { BaseApi } from "api/services/base-api"

export const GetAuthUserConfigurationApi = async (key: string = '') => {
  try {
    const response = await BaseApi.get(AUTH_USER_CONFIGURATION(key))
    return response
  } catch (error) {
    console.error('User Configuration Get Error occured ', error)
    throw error
  }
}

export const NotifyOwnerPaymentAPI = async (uuid: string) => {
  try {
    const response = await BaseApi.post(NOTIFY_OWNER_PAYMENT, { uuid })
    return response
  } catch (error) {
    console.error('Notifying Owner Payment Error occured ', error)
    throw error
  }
}

export const GetSideMenuListApi = async () => {
  try {
    const response = await BaseApi.get(APP_FEATURE_ENVIRONMENT)
    return response
  } catch (error) {
    console.error('Fetching Environment Feature Error occured ', error)
    throw error
  }
}

export const UpdateAuthUserSettingsApi = async (data: any = {}) => {
  try {
    const response = await BaseApi.patch(USER_SETTINGS, data)
    return response
  } catch (error) {
    console.error('User Configuration Get Error occured ', error)
    throw error
  }
}