import { _errorHandler, toastState } from 'utility/utils';
import { deleteAdminMenuListAPI, GetAdminMenuListAPI, GetOneAdminMenuListAPI, patchAdminMenuListAPI, postAdminMenuListAPI } from '../api-handler/menu-api';
import { toast } from 'react-toastify';

export const FetchAdminMenuList = async (setColumns: any, setQueryParams: any, setData: any, data: any, columnData: any, table: any) => {
  try {
    setColumns(columnData);
    const response = await GetAdminMenuListAPI();
    response?.data?.data?.length ? setData({ ...data, data: response?.data?.data, count: response?.data?.total_records?.count }) : setData({ ...data, data: [], count: 0 });
    table.resetRowSelection();
    setQueryParams((prev: any) => ({
      ...prev,
      offset: 2,
    }));
  } catch (error) {
    console.error(error);
  }
};

export const DeleteAdminMenuList = async (fetchList: any, uuid: any) => {
  try {
    const response = await deleteAdminMenuListAPI(uuid);
    if (!response?.error) {
      fetchList();
      toast.success('Menu List Deleted', toastState.success);
    } else {
      toast.error(_errorHandler(response?.message), toastState.error);
    }
  } catch (err) {
    console.error(err);
  }
};

export const createAdminMenuListApi = async (fetchList: any, onClose: any, handleState: any) => {
  try {
    const response = await postAdminMenuListAPI({ ...handleState });
    if (!response?.error) {
      fetchList();
      toast.success('Menu List Added', toastState.success);
      onClose();
    } else {
      toast.error(_errorHandler(response?.message), toastState.error);
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateAdminMenuListApi = async (fetchList: any, onClose: any, handleState: any, uuid: string) => {
  try {
    const response = await patchAdminMenuListAPI({ ...handleState, uuid });
    if (!response?.error) {
      fetchList();
      toast.success('Menu List Added Updated', toastState.success);
      onClose();
    } else {
      toast.error(_errorHandler(response?.message), toastState.error);
    }
  } catch (err) {
    console.error(err);
  }
};

export const getOneMenuListDataApi = async (setHandleState: any, uuid: string) => {
  try {
    const response = await GetOneAdminMenuListAPI(uuid);
    console.log('Response', response);
    response?.data
      ? setHandleState({
          menu_name: response?.data?.menu_name,
          menu_type: response?.data?.menu_type,
          order_index: response?.data?.order_index,
          action_list: response?.data?.action_list,
          menu_list_id: response?.data?.menu_list_id,
        })
      : setHandleState({
          menu_name: '',
          menu_type: '',
          order_index: 0,
          menu_list_id: '',
          action_list: [{ id: '', name: '', description: '', value: false }],
        });
    // table.resetRowSelection();
  } catch (error) {
    console.error(error);
  }
};
