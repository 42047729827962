import Intercom from '@intercom/messenger-js-sdk'
import { ENV_INTERCOM_APP_ID, ENV_INTERCOM_CREATED_AT } from 'config/envConfig'
import React from 'react'
import { useAppDispatch, useAppSelector } from 'redux-store'
import { getSideMenuListEvent } from 'shared/layout/event-handler'
import { _removeEmptyKeysFromObject } from 'utility/utils'

const AppLayout = () => {
    const dispatch = useAppDispatch()
    const logged_user = useAppSelector((state) => state.app);
    // update live site access menus
    React.useEffect(() => {
        dispatch(getSideMenuListEvent())
    }, [dispatch]);
    // update intercom user data
    Intercom(_removeEmptyKeysFromObject({
        app_id: ENV_INTERCOM_APP_ID,
        user_id: logged_user?.uuid,
        name: `${logged_user?.first_name || ''} ${logged_user?.last_name || ''}`.trim(),
        email: logged_user?.email,
        created_at: ENV_INTERCOM_CREATED_AT,
    }));
    return <></>
}

export default AppLayout
