
interface CheckBoxProps {
    info?: any,
    table?: any,
    lastSelectedItem?: any;
    onSelect: (value: any) => void;
    className?: string;
}

const CheckBox = (props: CheckBoxProps) => {
    const { info, table, lastSelectedItem, onSelect, className } = props

    const shiftSelect = (e: any) => {
        var _target;
        info?.row?.toggleSelected((_target = e.target) == null ? void 0 : _target.checked);

        if (e?.nativeEvent?.shiftKey) {
            const selection = (lastSelectedItem < info?.row?.id) ?
                table.getCoreRowModel().rows.slice(Number(lastSelectedItem), Number(info?.row?.id)) :
                table.getCoreRowModel().rows.slice(Number(info?.row?.id), Number(lastSelectedItem))

            selection.map((row: any) => {
                if (!row.getIsSelected()) row.toggleSelected()
                return ""
            })
            onSelect(info?.row?.id)
        }
        else {
            onSelect(info?.row?.id)
            // console.log(table?.getCoreRowModel().rowsById[0]?.toggleSelected())
        }

    }

    return (
        <input type="checkbox"
            id={`row-${info?.row?.id + 1}`}
            checked={info?.row?.getIsSelected()}
            // disabled={!info?.row?.getCanSelect()}
            // onChange={info?.row?.getToggleSelectedHandler()}
            onChange={e => shiftSelect(e)}
            className={`size-3.5 accent-brand-500 dark:accent-purple-600 cursor-pointer block ${className}`} />
    )
}

export default CheckBox