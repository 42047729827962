import { createSlice } from '@reduxjs/toolkit';
import { campaignInsightsState } from 'dto/types/redux-store/campaign-insights';

const initialState: campaignInsightsState = {
  prospectsList: [],
  lastSelectedItem: '',
  selectedRowItems: [],
  progressData: {},
  chartData: [],
  sequenceData: [],
};

const campaignInsightsSlice = createSlice({
  name: 'campaignInsights',
  initialState,
  reducers: {
    setProspectsList: (state, action) => {
      state.prospectsList = action.payload;
    },
    setLastSelectedItem: (state, action) => {
      state.lastSelectedItem = action.payload;
    },
    setSelectedRowItems: (state, action) => {
      state.selectedRowItems = action.payload;
    },
    setProgressData: (state, action) => {
      state.progressData = action.payload;
    },
    setChartData: (state, action) => {
      state.chartData = action.payload;
    },
    setSequenceData: (state, action) => {
      state.sequenceData = action.payload;
    },
  },
});

export const { setLastSelectedItem, setProspectsList, setSelectedRowItems, setProgressData, setChartData, setSequenceData } = campaignInsightsSlice.actions;

export default campaignInsightsSlice.reducer;
