import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BaseApi } from 'api/services/base-api';
const initialState: any = {
  error: null,
  loading: false,
  grouplist: [],
  searchList: [],
  toogleState: [],
};

export const fetchGroupsList = createAsyncThunk('grouplist/fetchData', async (_, { rejectWithValue }) => {
  try {
    const response = await BaseApi.get(`groups/linked/with/blacklist`);
    if (response?.data?.data && response?.data?.data?.length) {
      const finalData = await response?.data?.data?.sort((a: any, b: any) =>
        a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : 0
      );
      const states = await response?.data?.data?.reduce((acc: any, val: any) => {
        acc[val.uuid] = { show: false, rename: false };
        return acc;
      }, {});
      const payload: any = {
        finalData,
        states,
      };
      console.log('redux', payload);
      return payload;
    }
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

const groupListData = createSlice({
  name: 'grouplist',
  initialState,
  reducers: {
    setFetchData: (state, action: PayloadAction<any>) => {
      state.grouplist = action?.payload?.finalData;
      state.searchList = action?.payload?.finalData;
      state.toogleState = action?.payload?.states;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch data
      .addCase(fetchGroupsList.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(fetchGroupsList.fulfilled, (state, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.grouplist = action?.payload?.finalData;
        state.searchList = action?.payload?.finalData;
        state.toogleState = action?.payload?.states;
      })
      .addCase(fetchGroupsList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFetchData } = groupListData.actions;
export default groupListData.reducer;
