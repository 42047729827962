import { commonBooleanStateDTO, messageLoadingDTO, ticketDrawerDetailsType, ticketType, trueVerifierDataDTO, trueVerifierStateDTO } from 'dto/types/views/true-verfier';
import { queryParamsCommonIV, tableSelectionCommonIV } from './common';

export const ticketDrawerDetails: ticketDrawerDetailsType = {
  uuid: '',
  subject: '',
  emails: [],
  message: '',
  name: '',
};

export const ticket: ticketType = {
  ticket_id: '',
  status: 0,
  closed_date: '',
  created_date: '',
};

// Local States
export const trueVerifierDataIV: trueVerifierDataDTO = {
  data: [],
  count: 0,
};

export const trueVerifierStateIV: trueVerifierStateDTO = {
  data: trueVerifierDataIV,
  queryParams: queryParamsCommonIV,
  columns: [],
  tableLoading: false,
  roleStatus: {
    organization_access_list: {},
    organization_owner: false,
  },
  optionData: [],
  tableSelection: tableSelectionCommonIV,
};

export const messageLoadingIV: messageLoadingDTO = {
  messagesFetching: false,
  sending: false,
};

export const commonBooleanStateIV: commonBooleanStateDTO = {
  loading: false,
  showPaymentWarning: false,
  showPayment: false,
};
