import { Middleware } from '@reduxjs/toolkit';

export const authMiddleware: Middleware = (store) => (next) => (action) => {
  // const data = store.getState();
  // console.log('data', data);
  // if (!auth.isAuthenticated) {
  //   // logout -> navigate ('/login')
  // } else {
  next(action);
  // }
};
