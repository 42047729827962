import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    search: '',
    loading: false,
    page_loading: false,
    errorMessage: null,
    flag_status: false,
    drawer_status: false,
    popup_status: true,
    sidebar_full_view: false,
    sidemenu_click_status: true, // sidemenu click
    insufficient_credit_status: false,
    logged_user: null, // user
    theme: "light",
    headingTitle: {
        path: '',
        title: 'Sparkle',
        heading: '',
        navigation: { title: '', path: '' },
    },
};
const appGlobalStateSlice = createSlice({
    name: 'appGlobalState',
    initialState,
    reducers: {
        setAppGlobalState: (state, action) => {
            Object.assign(state, action.payload);
        },
        setDynamicAppGlobalData: (state, action: PayloadAction<{ key: string; value: any }>) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                state[key] = value;
            });
        },
        removeDynamicAppGlobalData(state, action: PayloadAction<string>) {
            const keyToRemove = action.payload;
            const { [keyToRemove]: _, ...rest } = state; // Omit the key
            return rest;
        },
        resetAppGlobalState: (state) => {
            Object.assign(state, initialState);
        }
    },
});

export const { setAppGlobalState, setDynamicAppGlobalData, removeDynamicAppGlobalData, resetAppGlobalState } = appGlobalStateSlice.actions;
export default appGlobalStateSlice.reducer;
