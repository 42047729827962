import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getEditData } from 'dto/initial-value/views/email-accounts';
import { EmailAccountStoreState } from 'dto/types/views/email-accounts';
// import { getEditData } from 'dto/initial-value/views/email-accounts';
// import { EmailAccountStoreState } from 'dto/types/views/email-accounts';

const initialState: EmailAccountStoreState = {
  getEditData: getEditData,
  smtpDrawerFlag: false,
  emailEditDrawer: false,
  customTrackingEnabled: true,
  emailGlobalOpt: false,
};

const emailAccountSlice = createSlice({
  name: 'email-accounts',
  initialState,
  reducers: {
    setSmtpDrawerFlagStatus: (state, action: PayloadAction<boolean>) => {
      state.smtpDrawerFlag = action.payload;
    },
    setEditData: (state, action: PayloadAction<object>) => {
      state.getEditData = action.payload;
    },
    handleChangeEditData: (state, action) => {
      const { key, value } = action.payload;
      state.getEditData = { ...state.getEditData, [key]: value };
    },
    setEmailEditDrawer: (state, action) => {
      state.emailEditDrawer = action.payload;
    },
    setCustomTrackingEnabled: (state) => {
      state.customTrackingEnabled = !state?.customTrackingEnabled;
    },
    setEmailGlobalOpt: (state, action) => {
      state.emailGlobalOpt = action.payload;
    },
  },
});

export const { setEditData, handleChangeEditData, setSmtpDrawerFlagStatus, setEmailEditDrawer, setCustomTrackingEnabled, setEmailGlobalOpt } = emailAccountSlice.actions;
export default emailAccountSlice.reducer;
