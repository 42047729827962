import { PayloadAction, createSlice } from '@reduxjs/toolkit';
const initialState: any = {
  data_list: [],
  total_count: 0,
  group_data_list: [],
  group_total_count: 0,
};

const senderAccountsData = createSlice({
  name: 'senderAccounts',
  initialState,
  reducers: {
    setSenderAccountsDataList: (state, action: PayloadAction<any>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setSenderAccountsDataList } = senderAccountsData.actions;
export default senderAccountsData.reducer;
