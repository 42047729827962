import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { BaseApi } from 'api/services/base-api';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { toastState } from 'utility/utils';
// import { useAppDispatch } from 'store';
// import { setTrigger } from 'store/sender-accounts';

interface ModalPropsDTO {
  isOpen?: boolean;
  onClose?: any;
}

const AccountAddEmailPopup = (props: ModalPropsDTO) => {
  const { isOpen, onClose } = props;
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);

  const createNewAdmin = async () => {
    try {
      setLoading(true);
      if (!email) {
        setLoading(false);
        return toast.error('Admin email required!', toastState);
      }
      const response = await BaseApi.post(`/admin-accounts/pull/emails`, { admin_email: email });
      if (response.data) {
        toast.success('Admin accounts added successfully!');
        // dispatch(setTrigger());
        setLoading(false);
        onClose();
        return;
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Modal isCentered isOpen={isOpen} onClose={() => onClose()} size={'lg'}>
        <ModalOverlay backdropFilter="blur(2px)" />
        <ModalContent overflow={'auto'} className="!p-0">
          <ModalHeader>
            <div className="flex flex-col">
              <h4 className="text-sm font-semibold text-heading">Add Admin Email</h4>
            </div>
            <ModalCloseButton size={'sm'} color={'column'} id="payment-pop-close" />
          </ModalHeader>
          <ModalBody className="bg-modalbg !p-4">
            <div className="flex items-center justify-center">
              <div className="w-[44rem] bg-white  px-6 py-6">
                {/* <div className=" flex h-24 select-none gap-5 rounded-md bg-white">
                  <Widget
                    onClick={() => {
                      handleGoogleOAuthSetup();
                      console.log('Drawer rendered in the DOM');
                    }}
                    icon={<FcGoogle size={35} />}
                    title={'Google'}
                    subtitle={'Gmail / G-Suite'}
                  />
                </div> */}
                <Input name="admin-email" placeholder="Admin email" onChange={(e) => setEmail(e.target.value)} />
                <div className="mt-6 flex gap-4">
                  <Button name="Cancel" customClass="w-full bg-white border !text-gray-800 !font-semibold text-[.82rem]" onClick={() => onClose()} />
                  <Button name="Continue" customClass="w-full bg-white border !text-white !font-semibold text-[.82rem] !bg-blueSecondary" onClick={() => createNewAdmin()} loading={loading} />
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AccountAddEmailPopup;
