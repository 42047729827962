import { createSlice } from '@reduxjs/toolkit';
import { ticket, ticketDrawerDetails } from 'dto/initial-value/views/true-verifier';
import { TrueVerifierState } from 'dto/types/views/true-verfier';
// import { ticket, ticketDrawerDetails } from 'dto/initial-value/views/true-verifier';
// import { TrueVerifierState } from 'dto/types/views/true-verfier';

const initialState: TrueVerifierState = {
  ticketDetailsStatus: false,
  ticketDrawerDetails: ticketDrawerDetails,
  ticketDrawerToggle: false,
  ticketId: '',
  totalMessages: [],
  totalMessagesCount: 0,
  chatText: '',
  ticketDetails: ticket,
};

const trueVerifierSlice = createSlice({
  name: 'true-verifier',
  initialState,
  reducers: {
    showTicketDetailsStatus: (state, action) => {
      state.ticketDetailsStatus = action.payload;
    },
    setToggleDrawer: (state) => {
      state.ticketDrawerToggle = !state.ticketDrawerToggle;
    },
    setTicketDrawerDetails: (state, action) => {
      state.ticketDrawerDetails = action.payload;
    },
    setTicketId: (state, action) => {
      state.ticketId = action.payload;
    },
    setTicketDetails: (state, action) => {
      state.ticketDetails = action.payload;
    },
    setTotalMessages: (state, action) => {
      // console.log('message payload',action?.payload)
      state.totalMessages = action.payload;
    },
    setTotalMessagesCount: (state, action) => {
      // console.log('payload',action?.payload)
      state.totalMessagesCount = action.payload;
    },
    setChatText: (state, action) => {
      // console.log('chat payload',action?.payload)
      state.chatText = action.payload;
    },
  },
});

export const { showTicketDetailsStatus, setTicketId, setTotalMessages, setChatText, setTicketDetails, setTotalMessagesCount, setToggleDrawer, setTicketDrawerDetails } =
  trueVerifierSlice.actions;
export default trueVerifierSlice.reducer;
