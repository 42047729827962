import { PayloadAction, createSlice } from '@reduxjs/toolkit';
const initialState: any = {
  notificationSettingsData: null,
  notificationSettingsType: null,
};

const NotificationSettingsData = createSlice({
  name: 'notificationSettings',
  initialState,
  reducers: {
    setNotificationData: (state, action: PayloadAction<any>) => {
      state.notificationSettingsData = action.payload;
    },
    setNotificationType: (state, action: PayloadAction<any>) => {
      state.notificationSettingsType = action.payload;
    },
  },
});

export const { setNotificationData, setNotificationType } = NotificationSettingsData.actions;
export default NotificationSettingsData.reducer;
