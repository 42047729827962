export const dmarcDto = {
    domain_policy_type: 'quarantine',
    sub_domain_policy_type: 'none',
    aggregate_email: '',
    forensic_email: '',
    report_format: 'default',
    reporting_interval: '86400',
    percentage: '0',
    dkim_identifier: 'relaxed',
    spf_identifier: 'relaxed',
}
export const spfDto = {
    allow_domain_mail: false,
    allow_mx_mail: false,
    approve_domain_hosts: false,
    other_mail_servers: false,
    host_name_a_record: [''],
    domain_name_mx_record: [''],
    ipv4: [''],
    ipv6: [''],
    include: [''],
    auth_failed_condition: '~all',

}
export const dkimDto = {
    dkim_selector: '',
    key_length: '1024'
}

export const domainHeaderSteps1 = [
    { stepNo: 1, name: 'DNS' },
    { stepNo: 2, name: 'DMARC' },
    { stepNo: 3, name: 'SPF' },
    { stepNo: 4, name: 'DKIM' },
    { stepNo: 5, name: 'SUMMARY' },
    { stepNo: 6, name: 'VERIFICATION' }
]

export const domainHeaderSteps2 = [
    { stepNo: 2, name: 'DMARC' },
    { stepNo: 3, name: 'SPF' },
    { stepNo: 4, name: 'DKIM' },
    { stepNo: 5, name: 'SUMMARY' },
    { stepNo: 6, name: 'VERIFICATION' }
]

export const domainEachRecordCount = [1, 9, 3, 2, 1, 1]

