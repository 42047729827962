import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from 'dto/initial-value/redux-store/organization';

const userSettingsSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganizationState: (state, action) => {
      Object.assign(state, action.payload);
    },
    setDynamicOrganizationData: (state: any, action: PayloadAction<{ key: string; value: any }>) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    removeDynamicOrganizationData(state: any, action: PayloadAction<string>) {
      const keyToRemove = action.payload;
      const { [keyToRemove]: _, ...rest } = state; // Omit the key
      return rest;
    },


    // setFormOverlay: (state, action: PayloadAction<boolean>) => {
    //   state.form_overlay = action.payload;
    // },
    // setProcessingStage: (state, action) => {
    //   state.processing_stage = action.payload;
    // },
    // setOrganizationId: (state, action) => {
    //   state.organization_id = action.payload;
    // },
    // setOrganization: (state, action) => {
    //   state.organization = { ...state.organization, ...action.payload };
    // },
    // setWorkspace: (state, action) => {
    //   state.workspace = { ...state.workspace, ...action.payload };
    // },
    // setswitchOrganizationModalView: (state, action) => {
    //   state.organization_view = action.payload;
    // },
    // resetOrganization: (state) => {
    //   state.organization = initialState.organization;
    // },
    // resetWorkspace: (state) => {
    //   state.workspace = initialState.workspace;
    // },
    setWorkspaceEditState: (state, action) => {
      state.workspaceEditState = { ...state.workspaceEditState, ...action.payload };
    },
    setTeamsWorkspaceEditState: (state, action) => {
      state.teamsWorkspaceEdit = { ...state.workspaceEditState, ...action.payload };
    },
    resetTeamsWorkspaceEditState: (state) => {
      state.teamsWorkspaceEdit = initialState.teamsWorkspaceEdit;
    },
    // setWorkspaceInviteId: (state, action) => {
    //   state.workspaceInviteId = action.payload;
    // },
    // setTeamsRoleEditState: (state, action) => {
    //   state.teamsRoleEdit = { ...state.teamsRoleEdit, ...action.payload };
    // },
    resetTeamsRoleEditState: (state) => {
      state.teamsRoleEdit = initialState.teamsRoleEdit;
    },
  },
});

export const {
  setOrganizationState,
  setDynamicOrganizationData,
  removeDynamicOrganizationData,
  // setFormOverlay,
  // setProcessingStage,
  // setOrganizationId,
  // setswitchOrganizationModalView,
  setWorkspaceEditState,
  setTeamsWorkspaceEditState,
  resetTeamsWorkspaceEditState,
  // setWorkspaceInviteId,
  // setTeamsRoleEditState,
  resetTeamsRoleEditState,
} = userSettingsSlice.actions;
export default userSettingsSlice.reducer;
