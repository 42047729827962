import { InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
// import { CustomMenuProps, dataType } from 'dto/types/components/custom-dropdown';
import { CustomMenu } from '../customMenu';
import React from 'react'
import { FiChevronDown, FiChevronUp, FiSearch } from 'react-icons/fi';
import { CustomMenuProps, dataType } from 'dto/types/components/custom-dropdown';


const CustomDropDown = (props: CustomMenuProps) => {
    const { dropDownData = [], onClick, onChange, btnId, label, btnContent, btnClassName, searchBar, select, menuListWidth, elementRootClassName, maxHeight, iconClassName, menuClassName, btnWidth, dropDownIcon, dropUpIcon, searchIcon, searchId, menuListClassName, elementClassName, searchClassname } = props
    const [menuData, setMenuData] = React.useState({
        menuView: false,
        searchData: [],
        selectedData: ''
    })

    React.useEffect(() => {
        setMenuData((prev) => ({
            ...prev,
            selectedData: label || 'Select',
            searchData: dropDownData
        }))
    }, [dropDownData, label])

    const handleSearchTimeZone = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onchange) {
            return onChange(event)
        }
        const searchValue = event.target.value.toLowerCase();
        const filteredData = searchValue
            ? dropDownData.filter(option => option.name.toLowerCase().includes(searchValue))
            : dropDownData;

        setMenuData(prev => ({
            ...prev,
            searchData: filteredData
        }));
    }

    const handleClick = (element: dataType) => {
        if (onClick) onClick?.(element);
        setMenuData((prev) => ({ ...prev, menuView: false, selectedData: element.name }))
    }

    const rightIcon = (dropDownIcon && dropUpIcon && menuData.menuView)
        ? dropDownIcon
        : dropUpIcon || menuData.menuView
            ? <FiChevronUp size={13} className={iconClassName} />
            : <FiChevronDown size={13} className={iconClassName} />

    return (
        <CustomMenu open={menuData.menuView} onOpen={() => setMenuData((prev) => ({ ...prev, menuView: true }))} onClose={() => setMenuData((prev) => ({ ...prev, menuView: false }))} menuClassName={`${menuClassName} relative`} btnContent={btnContent || menuData.selectedData} rightIcon={rightIcon} btnClassName={btnClassName || `bg-white border px-3 py-1.5 text-start text-xs text-gray-700 flex gap-1 justify-between items-center rounded-md hover:bg-gray-50 duration-300 w-full ${btnWidth} ${btnId}`} menuListClassName={`${menuListClassName || `${menuListWidth} mt-1 border border-gray-50 rounded-md shadow-md shadow-gray-300 max-h-${maxHeight || '72'}`} absolute z-50 bg-white overflow-y-auto`}>
            {
                searchBar && (
                    <div className='sticky top-0 bg-white px-3 pt-4 pb-2'>
                        <InputGroup id={searchId} className={searchClassname || 'bg-blue-50 rounded-2xl'} onChange={handleSearchTimeZone}>
                            <InputLeftElement pointerEvents='none' >
                                {searchIcon || <FiSearch className='text-gray-600' />}
                            </InputLeftElement >
                            <Input type='email' placeholder='Search' className='!rounded-2xl' />
                        </InputGroup>
                    </div>
                )
            }
            {
                select && (
                    <div id='select' onClick={() => handleClick({ id: 'select', name: label })} key={'selct'} className={elementClassName || 'my-2 p-2 hover:cursor-pointer text-sm duration-300 hover:bg-gray-50 active:bg-gray-100'} >
                        {label}
                    </div>
                )
            }
            <div className={elementRootClassName}>
                {menuData?.searchData.map((element, index) => (
                    <div id={`${element.name}-${element.id}`} onClick={() => handleClick(element)} key={index} className={elementClassName || 'my-2 p-2 rounded-md shadow-md border border-gray-100 shadow-gray-100 hover:cursor-pointer duration-300 hover:bg-gray-50 active:bg-gray-100'} >
                        {element.name}
                    </div>
                ))}
            </div>
        </CustomMenu>
    )
}

export default CustomDropDown