import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { crmOnboardTypes, PipelineState } from 'dto/types/views/crm-onboard';

const initialState: crmOnboardTypes = {
  brand: sessionStorage.getItem('brand') ? sessionStorage.getItem('brand') : '',
  pipelinename: sessionStorage.getItem('pipelinename') ? sessionStorage.getItem('pipelinename') : '',
  pipelineid: sessionStorage.getItem('pipelineid') ? sessionStorage.getItem('pipelineid') : '',
  stages: [],
  prospect_list: [],
  prospect_list_result: [],
  is_prospect_uploaded: false,
  email_account_connected_count: 0,
  calendar_connected_count: 0,
  filteredMapData: [],
};

const crmOnboardSlice = createSlice({
  name: 'crm-onboard',
  initialState,
  reducers: {
    getBrand: (state, action: PayloadAction<string>) => {
      sessionStorage.setItem('brand', action.payload);
      state.brand = action.payload;
    },
    getPipelineDetail: (state, action: PayloadAction<PipelineState>) => {
      sessionStorage.setItem('pipelineid', action.payload.pipelineid);
      sessionStorage.setItem('pipelinename', action.payload.pipelinename);
      state.pipelinename = action.payload.pipelinename;
      state.pipelineid = action.payload.pipelineid;
    },
    setPipelineStages: (state, action) => {
      state.stages = action.payload;
    },
    addPipelineStage: (state, action) => {
      state?.stages?.push(action.payload);
    },
    deletePipelineStage: (state, action) => {
      state.stages = state.stages.filter((_: any, i: any) => i !== action.payload);
    },
    updatePipelineStage: (state, action) => {
      const { index, stage } = action.payload;
      state.stages[index] = { ...state.stages[index], ...stage };
    },
    updateCalenderCount: (state, action) => {
      state.email_account_connected_count = action.payload.email_account_connected_count;
      state.calendar_connected_count = action.payload.calendar_connected_count;
    },
    setProspectList: (state, action) => {
      state.prospect_list = { ...state?.prospect_list, ...action.payload };
    },
    setProspectListResult: (state, action) => {
      state.prospect_list_result = [...state?.prospect_list_result, ...action.payload];
    },
    removeProspectListResult: (state) => {
      state.prospect_list_result = [];
      state.prospect_list = [];
    },
    setIsProspectUploaded: (state, actions) => {
      state.is_prospect_uploaded = actions.payload;
    },
    removePipelineData: (state) => {
      sessionStorage.removeItem('pipelineid');
      sessionStorage.removeItem('pipelinename');
      sessionStorage.removeItem('brand');
      state.brand = '';
      state.stages = [];
    },
    setFilteredMapData: (state, action) => {
      state.filteredMapData = [...state?.filteredMapData, ...action.payload];
    },
    resetFilteredMapData: (state) => {
      state.filteredMapData = [];
    },
  },
});

export const {
  getBrand,
  getPipelineDetail,
  setPipelineStages,
  addPipelineStage,
  deletePipelineStage,
  updatePipelineStage,
  setProspectList,
  setProspectListResult,
  setIsProspectUploaded,
  removeProspectListResult,
  removePipelineData,
  setFilteredMapData,
  resetFilteredMapData,
} = crmOnboardSlice.actions;
export default crmOnboardSlice.reducer;
