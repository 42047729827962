import { CustomState } from 'dto/types/redux-store/custom';

export const initialState: CustomState = {
  errorMessage: null,
  flagStatus: false,
  drawerStatus: false,
  popupStatus: true,
  lastSelectedItem: '',
  globalOpt: false,
  tableRowsList: [],
  credits: {
    totalAvailableCredits: 0,
    totalCredits: 0,
    hitCount: 0,
  },
  loading: false,
  global_loading: false,
  current_step_count: 1, // Step count for each section
  total_step_count: 1, // Total count for each section
  tableLoader: false,
  sideBarFullView: false,
  globalDependencyLoading: false,
  menu: {},
  headingTitle: {
    path: '',
    title: 'Sparkle',
    heading: '',
    navigation: { title: '', path: '' },
  },
  notificationCount: 0,
  file_upload:{
    isOpen:false,
    progress:0,
    file_name:'',
    file_data:[]
  },
  paymentStatus : false,
};
