/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
// import { jwtEncrypt, setAndGetQueryParams, toastState, useDebounce } from '/utils/utility';
import { MdAdd, MdLink, MdLinkOff } from 'react-icons/md';
import { FiSearch } from 'react-icons/fi';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { IoGitPullRequestOutline } from 'react-icons/io5';
import { Spinner } from '@chakra-ui/react';

import { BaseApi } from 'api/services/base-api';
import CustomFilter from 'components/common/customFilter';
import { setAndGetQueryParams, jwtEncrypt, toastState, useDebounce } from 'utility/utils';
import { OptionDataType } from 'dto/types/components/custom-filter';
import AccountDeligationPopup from '../components/AccountDeligationPopup';

const AdminSenderAccounts = () => {
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [btnIndex, setBtnIndex] = useState(0);
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get('code');
  const navigate = useNavigate();
  const [isInstructionOpen, setIsInstructionOpen] = useState(false);
  const [queryParams, setQueryParams] = React.useState({ limit: 25, offset: 0, sort: { columnName: '', order: '' }, search: '', filter: '' });

  const tableRef = React.useRef(null);
  const campaignSearchRef = React.useRef(null);
  const debouncedSearchValue = useDebounce(queryParams.search, 300);

  const [optionData, setOptionData] = useState<OptionDataType[]>([]);
  const [rowIndex, setRowIndex] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchList = async () => {
    try {
      // setLoader(true);
      const query = setAndGetQueryParams([
        { key: 'limit', value: queryParams?.limit },
        { key: 'offset', value: queryParams?.offset },
        { key: 'search', value: queryParams?.search },
      ]);
      const response = await BaseApi.get(`/app-admin/admin-accounts/get/account/by/domain?${query}`);
      // const { data, total_records }: any = jwtDecrypt(response?.data);
      if (response.data && response?.data?.data?.length) {
        setData((prev) => ({
          data: response.data.data,
          count: response?.data?.totalDomainCount?.[0]?.count,
        }));
      } else {
        setData((prev) => ({
          data: [],
          count: 0,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  React.useEffect(() => {
    fetchList();
  }, [queryParams?.limit, queryParams?.offset, debouncedSearchValue]);

  const handleUpdateAccountStatus = async (actionType: string = '', uuid: string = '') => {
    try {
      const response = await BaseApi.post(`/app-admin/admin-accounts/email/${actionType}`, { id_list: [uuid] });
      if (!response?.error || response?.status === 200) {
        const updateResponse = response?.data?.find((item: { uuid: string }) => item?.uuid === uuid);
        if (updateResponse?.data) {
          window.location.href = updateResponse?.data;
        } else {
          setData((prev) => {
            const data = prev.data?.map((item) => {
              if (item?.uuid === updateResponse?.uuid) {
                return { ...item, account_details: { ...item?.account_details, mailer_status: item?.account_details?.mailer_status ^ 1 } };
              }
              return item;
            });
            const count = prev?.count;
            return { data, count };
          });
        }
      }
    } catch (error: any) {
      console.error('handleUpdateAccountStatus Error:', error?.message);
    }
  };

  React.useEffect(() => {
    const data = [
      // { id: '1', name: 'Assignee', conditions: [{ id: "1", name: 'Is' }, { id: "2", name: 'Is not' }], resultValue: [{ id: "1", name: 'Me' }, { id: "2", name: 'P1' }, { id: "3", name: 'P2' }] },
      {
        id: '2',
        name: 'Status',
        uniqueColumnName: 'campaign_status',
        conditions: [
          { id: '1', name: 'Is', uniqueName: 'is' },
          { id: '2', name: 'Is not', uniqueName: 'not' },
        ],
        resultValue: [
          { id: '1', name: 'Paused', uniqueName: '3' },
          { id: '2', name: 'Active', uniqueName: '1' },
          { id: '3', name: 'Draft', uniqueName: '0' },
        ],
      },
      {
        id: '3',
        name: 'Open Rate',
        uniqueColumnName: 'open_rate',
        conditions: [
          { id: '1', name: 'More Than', uniqueName: 'gte' },
          { id: '2', name: 'Less than', uniqueName: 'lte' },
        ],
        resultValue: [],
      },
      {
        id: '4',
        name: 'Created On',
        uniqueColumnName: 'cdate',
        conditions: [{ id: '1', name: 'Is', uniqueName: 'is' }],
        resultValue: [
          { id: '1', name: 'Today', uniqueName: dateFormate(0, 0) },
          { id: '2', name: 'Yesterday', uniqueName: dateFormate(1) },
          { id: '3', name: 'Last 7 days', uniqueName: dateFormate(7) },
          { id: '4', name: 'Last 30 days', uniqueName: dateFormate(29) },
          { id: '5', name: 'This week', uniqueName: dateFormate('week', 0) },
          { id: '6', name: 'This month', uniqueName: dateFormate('month', 0) },
          { id: '7', name: 'Date Range' },
        ],
      },
    ];
    setOptionData(data);
  }, []);

  const handleFilter = async (filteredData: any) => {
    const encryptedData = jwtEncrypt(filteredData);
    setQueryParams((prev) => ({
      ...prev,
      filter: encryptedData,
    }));
  };

  const dateFormate = (from: number | string, to: number = 1) => {
    const fromDate =
      typeof from === 'string'
        ? moment()
            .startOf(from as moment.unitOfTime.StartOf)
            .format('YYYY-MM-DD')
        : moment().subtract(from, 'days').format('YYYY-MM-DD');
    const currentDate = moment().subtract(to, 'days').format('YYYY-MM-DD');
    return `${fromDate},${currentDate}`;
  };

  // console.log(dateFormate('month',0))
  const handleCampaignSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prev) => ({
      ...prev,
      search: event.target.value.trim().toLowerCase(),
    }));
  };

  const generateCodeToTokenAndUserInfo = useCallback(async (authCode: string) => {
    try {
      await BaseApi.patch('/app-admin/admin-accounts/auth/code', { code: authCode });
    } catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    if (authCode) {
      generateCodeToTokenAndUserInfo(authCode);
      navigate('/admin-accounts');
    }
  }, [authCode, generateCodeToTokenAndUserInfo, navigate]);

  const toggleIndexInArray = (index: number) => {
    setRowIndex((prevArr) => {
      if (prevArr.includes(index)) {
        return prevArr.filter((item) => item !== index);
      } else {
        return [...prevArr, index];
      }
    });
  };

  const createNewAdmin = async (email: string, index: number, e: any) => {
    try {
      e.stopPropagation();
      setBtnIndex(index);
      setLoading(true);
      if (!email) {
        setLoading(false);
        return toast.error('Admin email required!', toastState);
      }
      const response = await BaseApi.post(`/app-admin/admin-accounts/pull/emails`, { admin_email: email });
      if (response?.data) {
        toast.success('Admin accounts added successfully!');
        setLoading(false);

        return;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-navy-700 dark:text-white sm:overflow-x-auto`}>
      <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
        <div className="flex gap-2.5">
          <div
            className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline 
                 outline-0 focus-within:outline-2 focus-within:outline-brand-400 
                md:w-[13rem] lg:w-[26rem]"
            onClick={() => campaignSearchRef.current.focus()}
          >
            <FiSearch className="size-4 cursor-pointer" />
            <input
              ref={campaignSearchRef}
              type="text"
              placeholder="Search"
              id="campaign-search-input"
              className="w-full text-sm font-bold
                     text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-navy-700 dark:placeholder:text-white "
              onChange={handleCampaignSearch}
            />
          </div>
          {/* Custom filter */}
          <CustomFilter optionData={optionData} handleFilter={handleFilter} />
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => {
              setIsInstructionOpen(true);
            }}
            className="sender_accounts_add_email_btn mx-1 flex items-center justify-center gap-0.5 rounded-md bg-brand-500 px-2 py-1.5 text-sm font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            <MdAdd className="float-right text-lg" /> <span className="text-nowrap text-[11px]">Add Email</span>
          </button>
        </div>
      </div>
      <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden" ref={tableRef}>
        <>
          <div className="mt-5 flex items-center justify-between px-4 py-3 shadow-sm">
            <p className="w-1/6 text-center text-base font-medium">Domain</p>
            <p className="w-1/6 text-center text-base font-medium">Total Accounts count</p>
            <p className="w-1/6 text-center text-base font-medium">Connected Accounts count</p>
            <p className="w-1/6 text-center text-base font-medium">Disconnected Accounts count</p>
            <p className="w-1/6 text-center text-base font-medium">Pull New Emails</p>
          </div>

          {data?.data?.length
            ? data.data.map((item, index: number) => (
                <div>
                  <div className="mt-5 flex cursor-pointer items-center justify-between px-4 py-3 shadow-sm " onClick={() => toggleIndexInArray(index)}>
                    <p className="w-1/6 text-center text-base font-medium">{item._id}</p>
                    <p className="w-1/6 text-center text-base  font-medium ">{item.total}</p>
                    <p className="w-1/6 text-center text-base font-medium">{item.connected_account_count}</p>
                    <p className="w-1/6 text-center text-base font-medium">{item.disconnected_account_count}</p>
                    <div className="flex w-1/6 justify-center text-center text-base font-medium">
                      <button
                        type="button"
                        className={`transition-300 relative transition-all text-${'white'} bg-${'brand-500'} text-${'[0.7rem]'} ${'rounded-md'} ${'py-2'} ${'px-2.5'} 'bg-gray-200  'active:scale-95'} flex items-center justify-center gap-1  border         `}
                        onClick={(e: any) => createNewAdmin(item?.details?.[0]?.account_details?.email, index, e)}
                      >
                        <IoGitPullRequestOutline />
                        Pull New Emails
                        {loading && index === btnIndex && <Spinner className="absolute" thickness="3px" speed="0.65s" color="gray.400" size="md" />}
                      </button>
                    </div>
                  </div>
                  {rowIndex?.includes(index)
                    ? item?.details?.map((details: any) => (
                        <div className="mt-5 flex items-center justify-between px-4 py-3 shadow-sm">
                          <p className="w-1/6 text-center text-base font-medium">{details.name}</p>
                          <p className="w-1/6 text-center text-base font-medium">{details.variant}</p>
                          <p className="w-1/6 text-center text-base  font-medium ">{details.account_details.email}</p>
                          {details?.account_details.mailer_status === 0 ? (
                            <div onClick={() => handleUpdateAccountStatus('connect', details?.uuid)} className="flex w-min cursor-pointer items-center gap-1 rounded-full bg-[#FEF3F2] px-2 py-1 text-[0.7rem] font-semibold text-[#d32318]">
                              <MdLinkOff />
                              <span>Disconnected</span>
                            </div>
                          ) : (
                            <div onClick={() => handleUpdateAccountStatus('disconnect', details?.uuid)} className="flex w-min cursor-pointer items-center gap-1 rounded-full bg-[#ECFDF3] px-2 py-1 text-[.7rem] font-semibold text-[#029A48]">
                              <MdLink />
                              <span>Connected</span>
                            </div>
                          )}
                          <p className="w-1/6 text-center text-base  font-medium "></p>
                        </div>
                      ))
                    : null}
                </div>
              ))
            : null}
        </>
      </div>
      <AccountDeligationPopup isInstructionOpen={isInstructionOpen} onClose={() => setIsInstructionOpen(false)} />
    </div>
  );
};
export default AdminSenderAccounts;
