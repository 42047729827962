import React from 'react';
import { MdOutlineCalendarToday } from 'react-icons/md';
import DateRangeSelector from './DateRangeSelector';
import moment from 'moment';

const CustomDatePicker = (props: any) => {
  const { customClass = '', customSelectedDateClass = '', onChange = (date: any) => {} } = props;
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [selectedDate, setDate] = React.useState([]);

  React.useEffect(() => {
    onChange?.(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);
  return (
    <div className={`min-w-28: relative ${customClass}`}>
      <div className={`border-2 ${showCalendar ? 'border-menuBorder' : 'border-gray-100'} flex h-8 cursor-pointer items-center   gap-2 rounded-md bg-white px-3 py-3.5 shadow-bottom hover:border-menuBorder`} onClick={() => setShowCalendar(!showCalendar)}>
        <MdOutlineCalendarToday className="size-4 text-[#667085]" />
        <h5 className={`font-medium text-[#667085] ${customSelectedDateClass}`}>{selectedDate?.length ? `${moment(selectedDate[0]).format('MM/DD/YYYY')} - ${moment(selectedDate[1]).format('MM/DD/YYYY')}` : 'Select Date'}</h5>
        {/* <>{ queryParams?.start_date || queryParams?.end_date ? <></>: <RxCross2 className="size-4 text-[#667085]" onClick={()=>{}}/>}</> */}
      </div>
      {showCalendar && <DateRangeSelector selectedDate={selectedDate?.length ? selectedDate : [new Date(), new Date()]} setSelectedDate={setDate} setShowCalendar={setShowCalendar} right="right-0" top="top-9" />}
    </div>
  );
};

export default CustomDatePicker;
