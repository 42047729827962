export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_TIME_FORMAT = 'HH:mm:ss';
export const ISO_DATE_TIME_FORMAT = `${ISO_DATE_FORMAT}T${ISO_TIME_FORMAT}Z`;
export const TIME_ZONE_DATE_FORMAT = 'DD MMM YYYY, hh:mm A';

export const DISPLAY_DATE_FORMAT = 'DD MMM YYYY';
export const DISPLAY_TIME_FORMAT = 'hh:mm A';
export const DISPLAY_DATE_TIME_FORMAT = `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`;

export const SHORT_DATE_FORMAT = 'MM/DD/YYYY';
export const SHORT_TIME_FORMAT = 'HH:mm';

export const ONE_SECOND_IN_MS = 1000;
export const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS;
export const ONE_HOUR_IN_MS = 60 * ONE_MINUTE_IN_MS;
export const ONE_DAY_IN_MS = 24 * ONE_HOUR_IN_MS;
export const ONE_WEEK_IN_MS = 7 * ONE_DAY_IN_MS;
export const ONE_MONTH_IN_MS = 30 * ONE_DAY_IN_MS;
export const ONE_YEAR_IN_MS = 365 * ONE_DAY_IN_MS;

export const WEEKDAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const WEEKDAYS_LONG = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const MONTHS_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const MONTHS_LONG = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const CURRENT_YEAR = new Date().getFullYear();
export const CURRENT_MONTH = new Date().getMonth() + 1;
export const CURRENT_DAY = new Date().getDate();
export const TODAY = new Date().toISOString().split('T')[0];

export const TIMEZONES = {
  UTC: 'Coordinated Universal Time (UTC)',
  PST: 'Pacific Standard Time (PST)',
  EST: 'Eastern Standard Time (EST)',
  CET: 'Central European Time (CET)',
  IST: 'India Standard Time (IST)',
  GMT: 'Greenwich Mean Time (GMT)',
};

export const DEFAULT_TIMEZONE = 'UTC';

export const TODAY_RANGE = {
  start: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
  end: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
};

export const YESTERDAY_RANGE = {
  start: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
  end: new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
};

export const LAST_7_DAYS_RANGE = {
  start: new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(),
  end: new Date().toISOString(),
};

export const LAST_30_DAYS_RANGE = {
  start: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString(),
  end: new Date().toISOString(),
};

export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;
export const DAYS_PER_WEEK = 7;

export const secondsToMinutes = (seconds: number): number => seconds / SECONDS_PER_MINUTE;
export const minutesToHours = (minutes: number): number => minutes / MINUTES_PER_HOUR;
export const hoursToDays = (hours: number): number => hours / HOURS_PER_DAY;
export const daysToWeeks = (days: number): number => days / DAYS_PER_WEEK;

export const INTERVAL_ONE_SECOND = 1000;
export const INTERVAL_FIVE_SECONDS = 5000;
export const INTERVAL_ONE_MINUTE = 60 * 1000;
export const INTERVAL_FIVE_MINUTES = 5 * 60 * 1000;
export const INTERVAL_ONE_HOUR = 60 * 60 * 1000;
