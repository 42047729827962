import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from 'dto/initial-value/redux-store/custom';
const customSlice = createSlice({
  name: 'custom',
  initialState,
  reducers: {
    setGlobalDependencyLoading: (state, action: PayloadAction<boolean>) => {
      state.globalDependencyLoading = action.payload;
    },
    getErrorMessage: (state, action: PayloadAction<object>) => {
      state.errorMessage = { ...state.errorMessage, ...action.payload };
    },
    setFlagStatus: (state, action: PayloadAction<boolean>) => {
      state.flagStatus = action.payload;
    },
    setDrawerStatus: (state) => {
      state.drawerStatus = !state?.drawerStatus;
    },
    setPopupStatus: (state) => {
      state.popupStatus = !state?.popupStatus;
    },
    setGlobalOpt: (state, action) => {
      state.globalOpt = action.payload;
    },
    setTableRowsList: (state, action) => {
      state.tableRowsList = action.payload;
    },
    setCredits: (state, action) => {
      state.credits = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setGlobalLoading: (state, action) => {
      state.global_loading = action.payload;
    },
    setCurrentStepCount: (state, action) => {
      state.current_step_count = action.payload;
    },
    setTotalStepCount: (state, action) => {
      state.total_step_count = action.payload;
    },
    clearStepCount: (state) => {
      state.current_step_count = 1;
      state.total_step_count = 1;
    },
    setTableLoader: (state, action: PayloadAction<boolean>) => {
      state.tableLoader = action.payload;
    },
    setSideBarFullView: (state, action) => {
      state.sideBarFullView = action.payload;
    },
    setSideBarMenu: (state, action) => {
      state.menu = action?.payload;
    },
    setHeadingTitle: (state, action) => {
      state.headingTitle = action?.payload;
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    setPaymentStatus: (state, action) => {
      state.paymentStatus = action.payload;
    },
    // FileUpload
    setCommonFileData: (state, action) => {
      state.file_upload = { ...state.file_upload, ...action.payload };
    },
    clearFileData: (state) => {
      state.file_upload = {
        isOpen: false,
        progress: 0,
        file_name: '',
        file_data: [],
      };
    },
  },
});

export const {
  setGlobalDependencyLoading,
  getErrorMessage,
  setFlagStatus,
  setGlobalOpt,
  setCredits,
  setLoading,
  setGlobalLoading,
  setTotalStepCount,
  setCurrentStepCount,
  setDrawerStatus,
  setPopupStatus,
  setTableLoader,
  setSideBarFullView,
  setTableRowsList,
  setSideBarMenu,
  setHeadingTitle,
  setNotificationCount,
  clearStepCount,
  setCommonFileData,
  setPaymentStatus,
  clearFileData
} = customSlice.actions;
export default customSlice.reducer;
