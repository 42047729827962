/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode } from 'react';

interface PropType {
  loadMore: (setIsFetching: any) => Promise<void>;
  loader?: ReactNode;
  customClassName?: string;
}
const InfiniteScrollComponent = (props: PropType) => {
  const { loadMore, loader, customClassName } = props;
  const [isFetching, setIsFetching] = React.useState(false);
  const loaderRef = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting && !isFetching) {
        loadMore(setIsFetching);
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [isFetching, loadMore]);

  return (
    <div id="loader" className={`min-h-2 p-2 text-center font-bold text-brand-700 ${customClassName}`} ref={loaderRef}>
      {isFetching && loader}
    </div>
  );
};

export default InfiniteScrollComponent;
