import { createSlice } from '@reduxjs/toolkit';
import { UnmatchedStoreState } from 'dto/types/views/unmatched';

const initialState: UnmatchedStoreState = {
  groupList: [],
  searchGroupList: [],
  toogleGroupState: [],
  fetchGrouplistTrigger: false,
  mergeSearch: '',
  fetchAllDataTrigger: false,
};

const UnMatchedSlice = createSlice({
  name: 'Unmatched',
  initialState,
  reducers: {
    // new
    setFetchGroupListData: (state, action) => {
      state.groupList = action.payload.finalData;
      state.searchGroupList = action.payload.finalData;
      state.toogleGroupState = action.payload.states;
    },
    fetchGrouplistTrigger: (state) => {
      state.fetchGrouplistTrigger = !state.fetchGrouplistTrigger;
    },
    setMergeSearchWord: (state, action) => {
      state.mergeSearch = action.payload;
    },
    setMergeSearch: (state, action) => {
      state.mergeSearch = action.payload;
    },
    setfetchAllDataTrigger: (state) => {
      state.fetchAllDataTrigger = !state?.fetchAllDataTrigger;
    },
  },
});

export const { setFetchGroupListData, fetchGrouplistTrigger, setMergeSearch, setfetchAllDataTrigger } = UnMatchedSlice.actions;
export default UnMatchedSlice.reducer;
