import axios, { AxiosInstance } from 'axios';
import { ENV_API_BASEURL } from 'config/envConfig';
import { CONSTANTS } from 'constants/constants';
// import { API_BASEURL, LOGIN_STORAGE_VAR } from 'constants/constants';
import { toast } from 'react-toastify';
import { LocalStorage } from 'services/local.storage.service';
import { cryptoDecrypt, generateUUID, toastState } from 'utility/utils';
// import { cryptoDecrypt, generateUUID, toastState } from 'utility/utils';
// import { LocalStorage } from 'api/common/storage';
// import { LocalStorage } from './storage';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: ENV_API_BASEURL,
});

axiosInstance.interceptors.request.use(
  async (request: any) => {
    const state: any = LocalStorage.getString(CONSTANTS.LOGIN_STORAGE_VAR) ? await cryptoDecrypt(LocalStorage.getString(CONSTANTS.LOGIN_STORAGE_VAR), true) : null;
    request.headers = {
      'Client-Path': window.location.pathname,
      'Idempotency-Key': generateUUID(),
      'X-Correlation-ID': generateUUID(),
    };
    if (state) {
      request.headers.Authorization = 'Bearer ' + state?.authToken;
    }
    return request;
  },
  async (error) => {
    await Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      toast.error(error?.response?.data?.message || 'Unauthorized', toastState.error);
      LocalStorage.clearItem(CONSTANTS.LOGIN_STORAGE_VAR);
    } else if (error?.response?.status === 400) {
      toast.error(error?.response?.data?.message || 'Bad Request', toastState.error);
    } else if (error?.response?.status === 403) {
      toast.error(error?.response?.data?.message || 'Forbidden', toastState.error);
    } else if (error?.response?.status === 404) {
      toast.error(error?.response?.data?.message || 'Data not found', toastState.error);
    } else if (error?.response?.status === 409) {
      toast.error(error?.response?.data?.message || 'Conflict', toastState.error);
    } else if (error?.response?.status === 500) {
      toast.error(error?.response?.data?.message || 'Internal server error', toastState.error);
    } else if (error?.response?.status === 502) {
      toast.error(error?.response?.data?.message || 'Bad gateway', toastState.error);
    } else if (error?.response?.status === 503) {
      toast.error(error?.response?.data?.message || 'Service unavailable', toastState.error);
    } else if (error?.response?.status === 504) {
      toast.error(error?.response?.data?.message || 'Gateway timeout', toastState.error);
    } else if (error?.response?.status === 429) {
      toast.error(error?.response?.data?.message || 'Something went wrong', toastState.error);
    } else if (error?.response?.status === 422) {
      toast.error(error?.response?.data?.message || 'Unprocessable Entity', toastState.error);
    } else {
      toast.error(error?.response?.data?.message || 'Something went wrong', toastState.error);
    }
    return error?.response?.data;
  }
);

export default axiosInstance;
