import React from 'react'
interface tableViewType {
  headBg?: string; // Table head Background color
  rowBg?: string // Table body backgroun color
}
interface PropsType {
  width?: string; // Total view width
  height?: string; // Total view height
  column?: number;  // Number of column
  row?: number; // Number of row
  skeletonHeight?: string; // skeleton height
  skeletonBg?: string;  // skeleton background
  border?: string;  // Border for loader
  tableViewBg?: tableViewType; // Table backgroun color
  backgroundColor?: string; // Background color for all row and column
  columnWidth?: string; // Width for each column
  padding?: string //padding for cell
  cellBorder?: string //Border for cell
}
const TableLoader = (props: PropsType) => {
  const { width, height, column, row, skeletonHeight, skeletonBg, border, backgroundColor, columnWidth, tableViewBg, padding, cellBorder } = props
  const skWidth: any = columnWidth ? columnWidth.split(' ') : Math.floor(100 / column)
  const ColumnComponent = () => (
    <div className={`flex ${border} dark:!border-none`}>
      {
        Array.from({ length: column }, (_, index) => index).map((_, index) => {
          const cellWidth = columnWidth ? `${skWidth[index]}` : `${skWidth}%`;
          return (
            <div key={index} className={`${padding || 'p-3'} ${cellBorder}`} style={{ width: cellWidth }}>
              <div className={`${skeletonHeight || 'h-2'} w-full ${skeletonBg || 'bg-gray-500 dark:!bg-darkBorder'} animate-pulse`}></div>
            </div>
          );
        })
      }
    </div>
  )

  return (
    <div className={`flex flex-col bg-white dark:!bg-darkTwo ${height} ${width || 'w-full'}`}>
      {/* Row */}
      {/* Header */}
      <div className={`${tableViewBg?.headBg || backgroundColor} dark:bg-darkOne`}><ColumnComponent /></div>
      {/* Body */}
      {Array.from({ length: row }, (_, index: number) => index).map((val, i) => (<div key={i} className={`${tableViewBg?.rowBg || backgroundColor}`}><ColumnComponent /></div>))}
    </div>
  )
}

export default TableLoader