import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { dkimDto, dmarcDto, spfDto } from 'dto/initial-value/views/domain';
import { DomainStoreState } from 'dto/types/views/domain';
// import { dkimDto, dmarcDto, spfDto } from 'dto/initial-value/views/domain';
// import { DomainStoreState } from 'dto/types/views/domain';

const initialState: DomainStoreState = {
  dnsModal: false,
  selectedRows: [],
  completedStep: 1,
  domain_list: [],
  domains_uuid: [],
  domain_setup_flow: 1,
  record_setup_flow: 1,
  record_flow_count: 1,
  autofill: false,
  domain_setup_type: 1, // 1 - DNS, 2 - DMARC
  nsList: [],
  dmarc_records: dmarcDto,
  dmarc_Token: '',
  spf_records: spfDto,
  spf_Token: '',
  dkim_records: dkimDto,
  dkim_Token: '',
};

const domainSlice = createSlice({
  name: 'domain',
  initialState,
  reducers: {
    updateDNSModal: (state, action: PayloadAction<boolean>) => {
      state.dnsModal = action.payload;
    }, // -- DNS, DMARC, SPF, DKIM Stepper + Questionnaire Modal Status
    updateSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    }, // -- Selected row list of domain names
    setAddDomain: (state, action) => {
      const domain_list = action.payload.map((item: { id: number; name: string }) => item?.name);
      state.domain_list = domain_list;
    }, // -- Domain name list added by the user
    setDomainsUUID: (state, action) => {
      state.domains_uuid = action.payload;
    }, // -- Domain uuid list generated from Backend
    setDomainSetupType: (state, action) => {
      state.domain_setup_type = action.payload;
    }, // -- Domain Setup Type - DNS or DMARC
    setAutoFill: (state) => {
      state.autofill = !state.autofill;
    }, // -- Setup autofilled or not
    setDomainSetupFlow: (state, action) => {
      state.domain_setup_flow = action.payload;
      action.payload > state?.completedStep && (state.completedStep = action.payload);
    }, // -- Domain stepper Main Flow count
    setRecordSetupFlow: (state, action) => {
      state.record_setup_flow = action.payload;
    }, // -- Domain setup - Each stepper Record step Count
    setNSList: (state, action) => {
      state.nsList = action.payload;
    }, // -- NS List for DNS Setup
    setDMARC: (state, action) => {
      state.dmarc_records = { ...state.dmarc_records, ...action.payload };
    }, // -- DMARC Records List
    setSPF: (state, action) => {
      state.spf_records = { ...state.spf_records, ...action.payload };
    }, // -- SPF Records List
    setDKIM: (state, action) => {
      state.dkim_records = { ...state.dkim_records, ...action.payload };
    }, // -- DKIM Records List
    setDKIMSelectorList: (state, action) => {
      state.dkim_records.dkim_selector = action.payload;
    }, // -- DKIM Selectors List
    setRecordCount: (state, action) => {
      state.record_flow_count = action.payload;
    },
    setTokenRecord: (state, action) => {
      state.dmarc_Token = action?.payload?.dmarc_Token;
      state.spf_Token = action?.payload?.spf_Token;
      state.dkim_Token = action?.payload?.dkim_Token;
    },
    clearDomainSetup: (state) => {
      state.domain_list = [];
      state.domain_setup_flow = 1;
      state.record_setup_flow = 1;
      state.record_flow_count = 1;
      state.completedStep = 1;
      state.domains_uuid = [];
      state.autofill = false;
      state.domain_setup_type = 1;
      state.dmarc_records = dmarcDto;
      state.spf_records = spfDto;
      state.dkim_records = dkimDto;
      state.dmarc_Token = '';
      state.spf_Token = '';
      state.dkim_Token = '';
    }, // -- Clears the Redux store after closing the domain setup flow
  },
});

export const {
  updateDNSModal,
  setAddDomain,
  setDomainSetupFlow,
  setRecordSetupFlow,
  clearDomainSetup,
  setAutoFill,
  setDomainSetupType,
  setDomainsUUID,
  setDKIM,
  setDMARC,
  setSPF,
  setDKIMSelectorList,
  setRecordCount,
  updateSelectedRows,
  setNSList,
  setTokenRecord,
} = domainSlice.actions;
export default domainSlice.reducer;
