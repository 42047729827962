
export const getEditData = {
    id: '',
    uuid: '',
    email: '',
    name: '',
    picture: '',
    status: '',
    cdate: '',
    user_name: '',
    first_name: '',
    last_name: '',
    job: '',
    about_me: '',
    signature: '',
    random_delay_start: 0,
    random_delay_end: 0,
    limit: '',
    fixed_delay: '',
}

export const AddNewSMTPData = {
    first_name: '',
    last_name: '',
    email: '',
    smtp_username: '',
    smtp_password: '',
    smtp_host: '',
    smtp_port: '',
    smtp_secure: '',
    limit: '',
    fixed_delay: '',
    set_different_reply_to_address:'',
    imap_username: '',
    imap_password: '',
    imap_host: '',
    imap_port: '',
    imap_secure: '',
}