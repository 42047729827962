import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  source: '',
  flag_status: false,
  loading: false,
  infinite_scroll_loading: false,
  search: '',
  filter: '',
  limit: 25,
  offset: 1,
  sort: { columnName: '', order: '' },
  column_visibility: [],
  table_row_action_items_list: [],
};
const commonTableListSlice = createSlice({
  name: 'commontable',
  initialState,
  reducers: {
    updateTableQueryState: (state, action) => {
      Object.assign(state, action.payload);
    },
    setDynamicTableListData(state, action: PayloadAction<{ key: string; value: any }>) {
      const { key, value } = action.payload;
      let storeResponse: any = value
      if (state.hasOwnProperty(key) && state?.offset > 1) {
        const storeData = state?.[key]
        const updateStoreData = (state?.infinite_scroll_loading) ? [...storeData?.data, ...value?.data] : [...storeData?.data]
        storeResponse = { data: updateStoreData, total_records: value?.total_records }
      }
      return { ...state, [key]: storeResponse, };
    },
    removeDynamicTableListData(state, action: PayloadAction<string>) {
      const keyToRemove = action.payload;
      const { [keyToRemove]: _, ...rest } = state; // Omit the key
      return rest;
    },
  },
});

export const { updateTableQueryState, setDynamicTableListData, removeDynamicTableListData } = commonTableListSlice.actions;
export default commonTableListSlice.reducer;
