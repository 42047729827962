import { LOGIN_STORAGE_VAR } from 'constants/constants';
import { useEffect } from 'react';
import { Navigate, redirect, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux-store';
import { LocalStorage } from 'services/local.storage.service';
import { cryptoDecrypt } from 'utility/utils';

export const ProtectedHomeRouteMiddleware = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { app: { logged_user: isLoggedIn }, userAppConfiguration: { app_live_feature }, } = useAppSelector((state) => state);
  // console.log('app_live_feature', app_live_feature, isLoggedIn)
  useEffect(() => {
    const fetchAndValidateAccess = async () => {
      if (isLoggedIn && app_live_feature) {
        const decryptedFeature = await cryptoDecrypt(app_live_feature, true, true);
        const isAccessEnabled = decryptedFeature?.app_access_environment?.some((item: { baseurl: string }) =>
          location.pathname?.startsWith(item?.baseurl)
        );
        const isAdminRoute = isLoggedIn?.is_admin_account && location.pathname?.startsWith('/app-admin');

        if (!isAccessEnabled && !isAdminRoute) {
          window.location.href = decryptedFeature?.app_default_feature?.base_url;
        }
      }
    };
    fetchAndValidateAccess();
  }, [app_live_feature, location, isLoggedIn]);


  if (!LocalStorage.getString(LOGIN_STORAGE_VAR) && !isLoggedIn) {
    return <Navigate to="/sign-in" />;
  }
  return children;
};

export const CheckLoggedUserLoader = (app_default_feature: any) => {
  if (LocalStorage.getString(LOGIN_STORAGE_VAR)) {
    return redirect(app_default_feature?.base_url || '/campaigns');
  }
  return null;
};
