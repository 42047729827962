import { queryParamsCommonDTO, tableSelectionCommonDTO } from 'dto/types/views/common';

export const queryParamsCommonIV: queryParamsCommonDTO = {
  limit: 25,
  offset: 1,
  sort: { columnName: '', order: '' },
  search: '',
  filter: '',
};

export const tableSelectionCommonIV: tableSelectionCommonDTO = {
  selectedIds: [],
  globalOpt: false,
};
