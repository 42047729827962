import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ChakraProvider } from '@chakra-ui/react';
import AppBrowserRoutes from 'routes/routes';
import AppLayout from 'layouts/app-wrapper/AppLayout';

const App = () => {
  return (
    <ChakraProvider>
      <AppBrowserRoutes />
      <AppLayout />
      <ToastContainer />
    </ChakraProvider>
  );
};

export default App;
