import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import Button from 'components/common/Button';
import { CustomMenu } from 'components/common/customMenu';
import InputField from 'components/forms/InputField';
import React from 'react';
import { IoChevronDown } from 'react-icons/io5';
import { MdAdd, MdDelete } from 'react-icons/md';
// import { MdAdd, MdDelete } from 'react-icons/md';
import { createAdminMenuListApi, getOneMenuListDataApi, updateAdminMenuListApi } from 'shared/admin-panel/admin-menu-lists/event-handler/menu-events';

type NewMenuModalProps = {
  isOpen: boolean;
  onClose: () => void;
  uuid: string;
  fetchList: () => void;
};

const NewMenuModal = (props: NewMenuModalProps) => {
  const { isOpen, onClose, uuid, fetchList } = props;

  const [handleState, setHandleState] = React.useState({
    menu_name: '',
    menu_type: '',
    menu_list_id:'',
    order_index: 0,
    action_list: [{ id: '', name: '', description: '', value: false }],
  });

  const fetchEditData = async () => {
   await getOneMenuListDataApi(setHandleState,uuid)
  };

  React.useEffect(() => {
    uuid && fetchEditData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id: number) => {
    const data = handleState?.action_list?.slice();
    if (data?.length <= 1) return;
    data?.splice(id, 1);
    setHandleState((prev: any) => ({ ...prev, action_list: data }));
  };

  const handleCreate = async () => {
    await createAdminMenuListApi(fetchList, onClose, handleState);
  };

  const handleUpdate = async () => {
    await updateAdminMenuListApi(fetchList, onClose, handleState, uuid);
  };

  const handleMenuListList = (id: number, key: string, value: string | number | boolean) => {
    const data = handleState?.action_list?.slice();
    data[id] = { ...data[id], [key]: value };
    setHandleState((prev: any) => ({ ...prev, action_list: data }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'4xl'} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={0}>
          <div className="flex flex-col text-heading dark:!text-white">
            <span className="text-[1.15rem] font-semibold">Menu List</span>
            <span className="text-[0.65rem]">Create menu options for enhanced user role restriction</span>
          </div>
          <hr className="mt-4 border-t border-gray-200 dark:border-darkThree" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="mb-2 mt-1 flex w-full items-center justify-between gap-2 p-1">
            <div className="flex w-1/2 gap-3">
              <InputField
                autoComplete="off"
                extra="w-full"
                label="Menu Name *"
                customClass="!h-9 !mt-0 w-full"
                inputClass="placeholder:text-[0.85rem] !mt-0.5 !rounded-md !h-9"
                labelClass="!text-paused-color !ml-0 !font-[500]  !text-xs "
                placeholder="Menu name"
                id="menu_name"
                onChange={(e: any) => setHandleState((prevState: any) => ({ ...prevState, menu_name: e.target.value }))}
                value={handleState?.menu_name || ''}
                type="text"
                //   errorMessage={errorMessage?.first_name}
                //   state={errorMessage?.first_name ? 'error' : ''}
              />
            </div>
            <div className="flex w-1/2 gap-3">
              <InputField
                autoComplete="off"
                extra="w-full"
                label="Order Index"
                customClass="!h-9 !mt-0 w-full"
                inputClass="placeholder:text-[0.85rem] !mt-0.5 !rounded-md !h-9"
                labelClass="!text-paused-color !font-[500]  !text-xs "
                placeholder="Order Index"
                id="order_index"
                onChange={(e: any) => setHandleState((prevState: any) => ({ ...prevState, order_index: e.target.value }))}
                value={handleState?.order_index}
                type="number"
                min={0}
                //   errorMessage={errorMessage?.first_name}
                //   state={errorMessage?.first_name ? 'error' : ''}
              />
            </div>
          </div>

          <div className="mb-2 mt-1 flex w-full items-center justify-between gap-2 p-1">
           
            <div className="flex w-1/2 gap-3">
              <InputField
                autoComplete="off"
                extra="w-full"
                label="Menu List Id"
                customClass="!h-9 !mt-0 w-full"
                inputClass="placeholder:text-[0.85rem] !mt-0.5 !rounded-md !h-9"
                labelClass="!text-paused-color !ml-0 !font-[500]  !text-xs "
                placeholder="Menu List id"
                id="menu_list_id"
                onChange={(e: any) => setHandleState((prevState: any) => ({ ...prevState, menu_list_id: e.target.value }))}
                value={handleState?.menu_list_id}
                type="text"
                // min={0}
                //   errorMessage={errorMessage?.first_name}
                //   state={errorMessage?.first_name ? 'error' : ''}
              />
            </div>

            <div className="flex w-1/2 gap-3">
              <div className=" w-full items-center justify-between gap-2 mt-1">
                <h5 className="w-[7rem] text-xs font-medium dark:text-white">Menu Type</h5>
                <div className="mt-1 flex w-full">
                  <CustomMenu
                    menuClassName="w-full"
                    btnContent={<div className="flex  w-full items-center gap-2 whitespace-pre text-[0.85rem] font-thin text-blackDark">{handleState?.menu_type || `Select`}</div>}
                    btnClassName="w-full  justify-between items-center text-column !h-9"
                    rightIcon={
                      <div className="flex items-center gap-3">
                        <IoChevronDown className="size-3.5 font-medium text-[#2B3674]" />
                      </div>
                    }
                    menuListClassName="w-full !p-0"
                  >
                    <div className=" w-full !py-4">
                      <button
                        id="primary_goal_0"
                        onClick={() => {
                          setHandleState((prevState: any) => ({ ...prevState, menu_type: 'side_menu' }));
                          // setOpen(false);
                        }}
                        className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                      >
                        Side Menu
                      </button>
                      <button
                        id="primary_goal_0"
                        onClick={() => {
                          setHandleState((prevState: any) => ({ ...prevState, menu_type: 'settings_menu' }));
                          //   setOpen(false);
                        }}
                        className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                      >
                        Settings Side Menu
                      </button>
                    </div>
                  </CustomMenu>
                </div>
              </div>
            </div>
          </div>
          <hr className="border-t border-gray-200 dark:border-darkThree" />

          <hr className="border-t border-gray-200 dark:border-darkThree" />
          <div className="flex h-[40vh] w-full flex-col overflow-scroll py-2 pr-2">
            <h5 className="w-[7rem] text-sm font-medium dark:text-white">Options</h5>

            {handleState?.action_list?.map((item: any, i: number) => {
              return (
                <div className="my-4 flex w-full items-center justify-between gap-2 py-1" key={`${item?.credit}${i}`}>
                  <div className="flex w-1/5">
                    <CustomMenu
                      menuClassName="w-full"
                      btnContent={<div className="flex  w-full items-center gap-2 whitespace-pre text-[0.85rem] font-thin text-blackDark">{handleState?.action_list[i]?.id || `Id`}</div>}
                      btnClassName="w-full  justify-between items-center text-column !h-10"
                      rightIcon={
                        <div className="flex items-center gap-3">
                          <IoChevronDown className="size-3.5 font-medium text-[#2B3674]" />
                        </div>
                      }
                      menuListClassName="w-full !p-0"
                    >
                      <div className=" w-full !py-4">
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'id', 'view');
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          View
                        </button>
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'id', 'add');
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          Add
                        </button>
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'id', 'edit');
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          Edit
                        </button>
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'id', 'delete');
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          Delete
                        </button>
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'id', 'create');
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          Create
                        </button>
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'id', 'pause_or_resume');
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          Pause or Resume
                        </button>
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'id', 'download_or_export');
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          Download or Export
                        </button>
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'id', 'add_ticket');
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          Add Ticket
                        </button>
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'id', 'copy_or_duplicate');
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          Copy or Duplicate
                        </button>
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'id', 'archieve_or_delete');
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          Archieve or Delete
                        </button>
                      </div>
                    </CustomMenu>
                  </div>
                  <div className="flex w-1/5">
                    <InputField
                      autoComplete="off"
                      customClass="!h-9 !mt-0"
                      inputClass="placeholder:text-[0.85rem] !mt-0.5 !rounded-md !h-9"
                      labelClass="!text-paused-color !font-[500]  !text-xs "
                      placeholder="Name"
                      id="option_name"
                      onChange={(e: any) => handleMenuListList(i, 'name', e.target.value)}
                      value={handleState?.action_list[i]?.name}
                      type="text"
                      //   errorMessage={errorMessage?.first_name}
                      //   state={errorMessage?.first_name ? 'error' : ''}
                    />
                  </div>
                  <div className="flex w-1/5">
                    <InputField
                      autoComplete="off"
                      customClass="!h-9 !mt-0"
                      inputClass="placeholder:text-[0.85rem] !mt-0.5 !rounded-md !h-9"
                      labelClass="!text-paused-color !font-[500]  !text-xs "
                      placeholder="Description"
                      id="description"
                      onChange={(e: any) => handleMenuListList(i, 'description', e.target.value)}
                      value={handleState?.action_list[i]?.description}
                      type="text"
                      //   errorMessage={errorMessage?.first_name}
                      //   state={errorMessage?.first_name ? 'error' : ''}
                    />
                  </div>
                  <div className="flex w-1/5">
                    <CustomMenu
                      menuClassName="w-full"
                      btnContent={<div className="flex  w-full items-center gap-2 whitespace-pre text-[0.85rem] font-thin text-blackDark">{`${handleState?.action_list[i]?.value}` || `false`}</div>}
                      btnClassName="w-full  justify-between items-center text-column !h-10"
                      rightIcon={
                        <div className="flex items-center gap-3">
                          <IoChevronDown className="size-3.5 font-medium text-[#2B3674]" />
                        </div>
                      }
                      menuListClassName="w-full !p-0"
                    >
                      <div className=" w-full !py-4">
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'value', true);
                            //   setHandleState({ ...handleState, addon_type: 'default' });
                            //   setOpen(false);
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          True
                        </button>
                        <button
                          id="primary_goal_0"
                          onClick={() => {
                            handleMenuListList(i, 'value', false);

                            //   setHandleState({ ...handleState, addon_type: 'custom' });
                            //   setOpen(false);
                          }}
                          className="text-coloumn w-full p-1 pl-3 text-start text-xs hover:bg-lightPrimary dark:hover:text-blackDark"
                        >
                          False
                        </button>
                      </div>
                    </CustomMenu>
                  </div>

                  <button
                    onClick={() =>
                      setHandleState((prev: any) => ({
                        ...prev,
                        action_list: [...prev.action_list, { id: '', name: '', description: '', value: false }],
                      }))
                    }
                  >
                    <MdAdd className="size-5 rounded-full bg-blueSecondary p-1 text-white transition-all hover:p-0.5 hover:shadow-shade" />
                  </button>
                  {handleState?.action_list?.length > 1 && (
                    <button onClick={() => handleDelete(i)}>
                      <MdDelete className="size-5 rounded-full bg-blueSecondary p-1 text-white transition-all hover:p-0.5 hover:shadow-shade" />
                    </button>
                  )}
                </div>
              );
            })}
          </div>

          <hr className="border-t border-gray-200 dark:border-darkThree" />
        </ModalBody>

        <ModalFooter>
          <div className="mx-auto flex w-1/6 items-center gap-3">
            <Button id="subscriptionPlan-save-btn" name={uuid ? 'Update' : 'Save'} onClick={() => (uuid ? handleUpdate() : handleCreate())} customClass="text-[.8125rem] dark:border-none w-full active:scale-95 transition-transform duration-150 ease-out" />
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewMenuModal;
