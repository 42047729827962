import React from 'react';

type InputProps = {
  id?: string;
  value?: string;
  width?: string;
  placeholder?: string;
  customClass?: string;
  onChange?: (e: any) => void;
  disabled?: boolean;
  name?: string;
  inputType?: string;
};

const Input = (props: InputProps) => {
  const { width, placeholder, customClass, onChange, disabled, value, name, inputType, id } = props;

  return (
    <input
      id={id}
      name={name || ''}
      autoComplete="off"
      type={inputType || 'text'}
      className={`w-${
        width || 'full'
      } rounded-md border border-gray-200 p-2 text-xs text-[#667085] focus:text-blackDark focus:outline focus:outline-2 focus:outline-menuBorder ${customClass}`}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e)}
    />
  );
};

export default Input;
