import { LOGIN_STORAGE_VAR } from 'constants/constants'
import { AppDispatch } from 'redux-store'
import { setAppSettings, setLoggedUserDetails, setLoggedUserRolePermissionList } from 'redux-store/authentication'
import { LocalStorage } from 'services/local.storage.service'
import { _removeEmptyKeysFromObject, cryptoDecrypt } from 'utility/utils'
import { GetAuthUserConfigurationApi, GetSideMenuListApi, NotifyOwnerPaymentAPI, UpdateAuthUserSettingsApi } from '../api-handler'
import { NavigateFunction } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setPaymentStatus } from 'redux-store/account-settings'
import { BILLING } from 'api/endpoints/billingEndpoints'
import { setAppGlobalState } from 'redux-store/app-global-state'
import { setDynamicUserAppConfigurationData, setUserAppConfigurationState } from 'redux-store/user-app-configuration'

export const handleAppStateManagement = (loggedUser: any, environment: any, dispatch: AppDispatch) => {
  const theme = LocalStorage.getItem('darkMode') === 'true' ? true : false
  updateLoggeduserDataToStore(dispatch)
  // getConfigMiddleware(dispatch);
  if (!Object.keys(loggedUser).length) {
    return
  }

  dispatch(setAppSettings({ key: 'theme', value: theme ? 'dark' : 'light' }))
  if (environment?.app_default_feature?.is_org_enbaled) {
  }
}

export const updateLoggeduserDataToStore = async (dispatch: AppDispatch) => {
  const decodedData = await cryptoDecrypt(LocalStorage.getString(LOGIN_STORAGE_VAR), true)
  dispatch(
    setLoggedUserDetails(_removeEmptyKeysFromObject({
      uuid: decodedData?.uuid,
      is_admin_account: decodedData?.is_admin_account,
      first_name: decodedData?.first_name,
      last_name: decodedData?.last_name,
      email: decodedData?.email,
      last_switch_organization_id: decodedData?.last_switch_organization_id,
      last_switch_workspace_id: decodedData?.last_switch_workspace_id,
      authToken: decodedData?.authToken,
      picture: decodedData?.picture,
    }))
  )
  dispatch(setLoggedUserRolePermissionList(decodedData?.role_permission_list))
  const prevDarkMode = LocalStorage.getItem('darkMode')
  const theme = prevDarkMode === 'true' ? true : false
  theme && document.body.classList.add('dark')
}

export const getAuthUserConfiguration = async (data: string, dispatch: AppDispatch) => {
  try {
    const response = await GetAuthUserConfigurationApi(data)
    if (!response?.error) {
      data ? dispatch(setDynamicUserAppConfigurationData({ ...response?.data })) : dispatch(setDynamicUserAppConfigurationData({ ...response?.data }))
    }
  } catch (err) {
    console.error(err);
  }
};

export const getAuthUserConfigurationEvent = () => {
  return async (dispatch: any, getReduxStoreState: any) => {
    try {
      const currentState = getReduxStoreState();
      const apiResponse = await GetAuthUserConfigurationApi(currentState?.userAppConfiguration?.source)
      if (!apiResponse?.error || apiResponse?.status === 200) {
        dispatch(setDynamicUserAppConfigurationData({ ...apiResponse?.data }));
        const useSettingDetails = apiResponse?.data?.user_setting_details?.data;
        if (useSettingDetails) {
          dispatch(setAppGlobalState({ sidebar_full_view: useSettingDetails?.side_menu?.toggle, theme: useSettingDetails?.theme }));
          if (useSettingDetails?.theme === 'light') {
            document.body.classList.remove('dark');
          } else {
            document.body.classList.add('dark');
          }
        }
      }
    } catch (error) {
      console.error('getAuthUserConfigurationEvent Error:', error);
    }
  };
};

export const updateAuthUserSettingsEvent = () => {
  return async (dispatch: any, getReduxStoreState: any) => {
    try {
      const currentState = getReduxStoreState();
      dispatch(setAppGlobalState({ sidebar_full_view: !currentState?.app?.sidebar_full_view }));
      const apiResponse = await UpdateAuthUserSettingsApi({ side_menu: { toggle: !currentState?.app?.sidebar_full_view } })
      if (!apiResponse?.error || apiResponse?.status === 200) {
      }
    } catch (error) {
      console.error('updateAuthUserSettingsEvent Error:', error);
    }
  };
};

export const getSideMenuListEvent = () => {
  return async (dispatch: any) => {
    try {
      const apiResponse = await GetSideMenuListApi()
      if (!apiResponse?.error || apiResponse?.status === 200) {
        // const appAccessEnabledFeature = await cryptoDecrypt(apiResponse?.data, true, true);
        dispatch(setUserAppConfigurationState({ app_live_feature: apiResponse?.data }));
      }
    } catch (error) {
      console.error('getSideMenuListEvent Error:', error);
    }
  };
};

export const updateLoggedUserDataEvent = () => {
  return async (dispatch: any) => {
    try {
      const decodedData = await cryptoDecrypt(LocalStorage.getString(LOGIN_STORAGE_VAR), true)
      const loggedUserData = _removeEmptyKeysFromObject({
        uuid: decodedData?.uuid,
        is_admin_account: decodedData?.is_admin_account,
        first_name: decodedData?.first_name,
        last_name: decodedData?.last_name,
        email: decodedData?.email,
        last_switch_organization_id: decodedData?.last_switch_organization_id,
        last_switch_workspace_id: decodedData?.last_switch_workspace_id,
        authToken: decodedData?.authToken,
        picture: decodedData?.picture,
      })
      dispatch(setAppGlobalState({ logged_user: loggedUserData }))
    } catch (error) {
      console.error('updateLoggedUserDataEvent Error:', error);
    }
  };
};

export const notifyCreditPaymentToOwnerEvent = (userType: string, orgId: string, navigate: NavigateFunction) => {
  return async (dispatch: AppDispatch) => {
    try {
      if (userType === 'owner') {
        navigate(BILLING);
        dispatch(setAppGlobalState({ insufficient_credit_status: false, loading: false }))
      } else {
        dispatch(setAppGlobalState({ loading: true }))
        const apiResponse = await NotifyOwnerPaymentAPI(orgId)
        if (!apiResponse?.error || apiResponse?.status === 200) {
          toast.success('Notification sent Successfully');
        }
      }
    } catch (error) {
      console.error('notifyCreditPaymentToOwnerEvent Error:', error);
    } finally {
      dispatch(setAppGlobalState({ insufficient_credit_status: false, loading: false }))
    }
  };
};

export const handleInsufficientBalanceFunction = async (userType: any, uuid: any, dispatch: AppDispatch, navigate: NavigateFunction, setBtnLoading: (value: React.SetStateAction<boolean>) => void) => {
  if (userType === 'owner') {
    dispatch(setPaymentStatus(false));
    navigate(BILLING);
  } else {
    setBtnLoading(true);
    try {
      const response: any = await NotifyOwnerPaymentAPI(uuid)
      if (!response?.error) {
        dispatch(setPaymentStatus(false));
        toast.success('Notification sent Successfully');
      }
      setBtnLoading(false);
    } catch (error) {
      console.error('Error from Notify:', error);
    }
  }
};


