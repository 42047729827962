import React from 'react';
import { FaEyeSlash, FaRegEye } from 'react-icons/fa';

function InputField(props: {
  id?: string;
  name?: string;
  label?: string;
  extra?: string;
  placeholder?: string;
  variant?: string;
  state?: string;
  disabled?: boolean;
  type?: string;
  errorMessage?: string;
  maxLength?: number;
  refs?: any;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: any;
  onPaste?: any;
  autoComplete?: string;
  value?: string | number;
  inputClass?: string;
  labelClass?: string;
  accept?: string;
  defaultValue?: string;
  customClass?: string;
  readOnly?: boolean;
  min?: number;
  max?: number;
  keys?: string | number;
  rightElement?: any;
  onBlur?: any
}) {
  const {
    id,
    name,
    label,
    extra,
    placeholder,
    variant,
    state,
    disabled,
    type = 'text', // Default to 'text' if no type is provided
    errorMessage,
    refs,
    onChange,
    onKeyDown,
    onPaste,
    autoComplete,
    value,
    inputClass,
    labelClass,
    accept,
    defaultValue,
    customClass,
    readOnly,
    min,
    max,
    keys,
    maxLength,
    rightElement,
    onBlur
  } = props;

  const [passwordVisible, setPasswordVisible] = React.useState(type === 'password');
  const inputType = type === 'password' && !passwordVisible ? 'text' : type;
  return (
    <div className={`${extra}`} key={keys}>
      {label && (
        <label htmlFor={id} className={`${labelClass || ''} text-sm text-navy-700 dark:!text-white ${variant === 'auth' ? 'ml-1.5 font-medium' : 'ml-3 font-bold'}`}>
          {label}
        </label>
      )}

      <div className={`relative  mt-2 flex h-12 items-center ${customClass || ''}`}>
        <input
          key={keys}
          autoComplete={autoComplete}
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          disabled={disabled}
          type={inputType}
          accept={accept || ''}
          id={id}
          ref={refs}
          defaultValue={defaultValue}
          name={name}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly}
          min={min}
          max={max}
          maxLength={maxLength}
          className={` h-full rounded-xl border bg-white/0 px-3 text-sm outline-none dark:!bg-darkThree ${disabled
              ? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
              : state === 'error'
                ? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
                : state === 'success'
                  ? 'border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
                  : 'border-gray-200 dark:!border-white/10 dark:text-white'
            } ${inputClass}  w-full`}
        />
        {rightElement && <span className="absolute  right-2 text-gray-700 ">{rightElement}</span>}
        {type === 'password' && (
          <span className="absolute right-3 cursor-pointer" onClick={() => setPasswordVisible(!passwordVisible)}>
            {passwordVisible ? <FaRegEye className="text-column" size={16} /> : <FaEyeSlash className="text-column" size={16} />}
          </span>
        )}
      </div>

      {errorMessage && <span className="ml-1 mt-1 flex items-center text-xs font-medium tracking-wide text-red-500 dark:!text-red-400">{errorMessage}</span>}
    </div>
  );
}

export default InputField;
