import { createSlice } from '@reduxjs/toolkit';

interface UnifiedInboxStoreInterface {
  InboxlistData: any;
  inbox_status?: string;
  variant: string;
  clearSelectData: Array<string>;
  searchQuery: string;
  filter: string;
  limit: number;
  offset: number;
  groupList?: Array<any>;
  searchGroupList?: Array<any>;
  toogleGroupState?: Array<any>;
  fetchGrouplistTrigger?: boolean;
  isSnoozePopupOpen: boolean;
  snoozeId: string;
  triggerCount: boolean;
}

const initialState: UnifiedInboxStoreInterface = {
  InboxlistData: [],
  inbox_status: 'pending',
  variant: 'All',
  clearSelectData: [],
  searchQuery: '',
  filter: '',
  limit: 25,
  offset: 2,
  groupList: [],
  searchGroupList: [],
  toogleGroupState: [],
  fetchGrouplistTrigger: false,
  isSnoozePopupOpen: false,
  snoozeId: '',
  triggerCount: false,
};

const unifiedInboxSlice = createSlice({
  name: 'unifiedInboxData',
  initialState,
  reducers: {
    setInboxlistData: (state, action) => {
      state.InboxlistData = action.payload;
    },
    setVariant: (state, action) => {
      state.variant = action.payload;
    },
    setClearSelectData: (state, action) => {
      const uuid = action.payload;
      if (state.clearSelectData.includes(uuid)) {
        state.clearSelectData = state.clearSelectData.filter((item) => item !== uuid);
      } else {
        state.clearSelectData.push(uuid);
      }
    },
    RemoveClearSelectData: (state) => {
      state.clearSelectData = [];
    },
    setinboxStatus: (state, action) => {
      state.inbox_status = action.payload;
    },
    setVariantType: (state, action) => {
      state.variant = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setFilterQuery: (state, action) => {
      state.filter = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setOffset: (state, action) => {
      return {
        ...state,
        offset: state.offset + action.payload,
      };
    },
    setFetchGroupListData: (state, action) => {
      state.groupList = action.payload.finalData;
      state.searchGroupList = action.payload.finalData;
      state.toogleGroupState = action.payload.states;
    },
    setfetchGrouplistTrigger: (state) => {
      state.fetchGrouplistTrigger = !state.fetchGrouplistTrigger;
    },
    setSnoozePopupState: (state, action) => {
      state.isSnoozePopupOpen = action.payload;
    },
    setSnoozeId: (state, action) => {
      state.snoozeId = action.payload;
    },
    setTriggerCount: (state, action) => {
      state.triggerCount = action.payload;
    },
  },
});

export const {
  setInboxlistData,
  setVariant,
  setClearSelectData,
  setFetchGroupListData,
  RemoveClearSelectData,
  setinboxStatus,
  setSearchQuery,
  setLimit,
  setOffset,
  setfetchGrouplistTrigger,
  setSnoozePopupState,
  setSnoozeId,
  setTriggerCount,
  setFilterQuery,
} = unifiedInboxSlice.actions;
export default unifiedInboxSlice.reducer;
