import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface GlobalSettingsState {

  globalSetting?: any;
  globalConfiguration?: any;
  updateSettingsData?: {
    updateState?: boolean;
    updateSettingData?: string;
  };
  environment: any
}

const initialState: GlobalSettingsState = {


  globalConfiguration: {},
  updateSettingsData: {
    updateState: false,
    updateSettingData: '',
  },
  environment: {}
};

const globalConfigurationSlice = createSlice({
  name: 'global-configuration',
  initialState,
  reducers: {
    setGlobalConfiguration: (state, action: PayloadAction<object>) => {
      state.globalConfiguration = { ...state?.globalConfiguration, ...action?.payload };
    },
    setUpdateSettingsData: (state, action: PayloadAction<any>) => {
      state.updateSettingsData = {
        updateState: !state?.updateSettingsData?.updateState,
        updateSettingData: action?.payload
      };
    },
    setEnvironment: (state, action: PayloadAction<object>) => {
      state.environment = action.payload;
    },
  },
});

export const { setGlobalConfiguration, setUpdateSettingsData, setEnvironment } = globalConfigurationSlice.actions;
export default globalConfigurationSlice.reducer;
