import { ADMIN_MENU_LIST, GET_ONE_ADMIN_MENU_LIST } from 'api/endpoints/adminPannelEndpoints';
import { BaseApi } from 'api/services/base-api';
import { AxiosResponse } from 'axios';

export const GetAdminMenuListAPI = async () => {
  try {
    const response: AxiosResponse<any, any> = await BaseApi.get(ADMIN_MENU_LIST);
    return response;
  } catch (error) {
    console.error('Failed to get Menu list:', error);
  }
};

export const GetOneAdminMenuListAPI = async (uuid: string) => {
  try {
    const getUrl = GET_ONE_ADMIN_MENU_LIST(uuid);
    const response: AxiosResponse<any, any> = await BaseApi.get(getUrl);
    return response;
  } catch (error) {
    console.error('Failed to get Menu list:', error);
  }
};

export const deleteAdminMenuListAPI = async (uuid: string) => {
  try {
    const getUrl = ADMIN_MENU_LIST;
    const response = await BaseApi.delete(getUrl, { data: { uuid: [uuid] } });
    return response;
  } catch (error) {
    console.error('Admin Menu list DeleteApi Error:', error);
    throw error;
  }
};

export const postAdminMenuListAPI = async (data: any) => {
  try {
    const getUrl = ADMIN_MENU_LIST;
    const response = await BaseApi.post(getUrl, data);
    return response;
  } catch (error) {
    console.error('Admin Menu list DeleteApi Error:', error);
    throw error;
  }
};

export const patchAdminMenuListAPI = async (data: any) => {
  try {
    const getUrl = ADMIN_MENU_LIST;
    const response = await BaseApi.patch(getUrl, data);
    return response;
  } catch (error) {
    console.error('Admin Menu list DeleteApi Error:', error);
    throw error;
  }
};
