import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    source: '', // user_settings, org_ws, notification, billings, role_permission
    flag_status: false,
    app_live_feature: '',
    // dynamically generate the value and key listed below the commanted keys
    // app_live_feature:""
    // billing_details: {},
    // org_ws_details: {}, 
    // user_notification_count: {},
    // user_organization_role_details: {},
    // user_setting_details: []
};
const userAppConfigurationStateSlice = createSlice({
    name: 'userAppConfigurationState',
    initialState,
    reducers: {
        setUserAppConfigurationState: (state, action) => {
            Object.assign(state, action.payload);
        },
        setDynamicUserAppConfigurationData: (state, action: PayloadAction<{ key: string; value: any }>) => {
            Object.entries(action.payload).forEach(([key, value]) => {
                state[key] = value;
            });
        },
        removeDynamicUserAppConfigurationData(state, action: PayloadAction<string>) {
            delete state[action.payload];
        },
    },
});

export const { setUserAppConfigurationState, setDynamicUserAppConfigurationData, removeDynamicUserAppConfigurationData } = userAppConfigurationStateSlice.actions;
export default userAppConfigurationStateSlice.reducer;
