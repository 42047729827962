import React from 'react';
import { IoMdAdd } from 'react-icons/io';
import { VscListFilter } from 'react-icons/vsc';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { CustomMenu } from '../customMenu';
import { dataType } from 'dto/types/components/custom-dropdown';
import { useDebounce } from 'utility/utils';
import { FilterPropsType, FilterType, NestedFilterOptionsType, NestedFilterType } from 'dto/types/components/custom-filter';
import CustomDropDown from '../customDropDown';
import CustomDatePicker from '../customDatePicker';
import moment from 'moment';

const CustomFilter = (props: FilterPropsType) => {
  const { optionData, handleFilter, id = 'filter-btn', position }: any = props;
  // console.log("optionData",optionData)
  const [filters, setFilters] = React.useState<FilterType[]>([]);
  // Common option data
  const dropDownData = optionData?.map((item: any) => ({ id: item.id, name: item.name, uniqueName: item?.uniqueColumnName }));
  //    Initial value for filter
  React.useEffect(() => {
    const newFilter = { id: '1', name: 'Where', nestedFilters: [{ id: '1', name: 'Where', nestedFilterOptions: {} as NestedFilterOptionsType }] };
    setFilters([newFilter]);
  }, []);

  // Duplicate state to call the useEffect function
  const [duplicateData, setDuplicateData] = React.useState({ data: '', toggle: false });
  const [debounceState, setDebounceState] = React.useState('');
  const searchDebounce = useDebounce(debounceState, 300);
  React.useEffect(() => {
    if (duplicateData?.data) {
      const filterData: any = {};
      // const filterCondition = filters[1]?.nestedFilters[1]?.nestedFilterOptions?.resultValue?.value ? filters[1]?.name.toLowerCase() : 'and';
      const filterCondition = filters[1]?.nestedFilters[0]?.nestedFilterOptions?.resultValue?.value ? filters[1]?.name.toLowerCase() : 'and'; // Modified by dhanush on 03/10/2024
      filterData['type'] = filterCondition;
      filterData['data'] = filters
        .map((filter) => {
          const nestedData: any = filter?.nestedFilters
            ?.map((nestedFilter) => {
              if (!nestedFilter?.nestedFilterOptions?.resultValue || (typeof nestedFilter?.nestedFilterOptions?.resultValue?.uniqueName === 'string' && nestedFilter?.nestedFilterOptions?.resultValue?.uniqueName?.length <= 0)) return null;
              return {
                column: nestedFilter?.nestedFilterOptions?.mainValue?.uniqueName,
                condition: nestedFilter?.nestedFilterOptions?.condition?.uniqueName,
                value: nestedFilter?.nestedFilterOptions?.resultValue?.uniqueName,
              };
            })
            .filter(Boolean);
          if (!nestedData.length) return null;
          const nestedFilterCondition = filter?.nestedFilters[1]?.nestedFilterOptions?.resultValue?.value ? filter?.nestedFilters[1]?.name.toLowerCase() : 'and';
          return {
            type: nestedFilterCondition,
            data: nestedData,
          };
        })
        .filter(Boolean);
      handleFilter?.(filterData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateData?.toggle, searchDebounce]);

  // Add new nested filter
  const handleAddNestedFilter = (parentId: string) => {
    setFilters((prevFilters) => {
      const updatedFilters = prevFilters.map((item) => {
        if (item.id === parentId) {
          const lastIndex = item?.nestedFilters?.length - 1;
          const id = (parseInt(item?.nestedFilters[lastIndex]?.id) + 1).toString();
          return {
            ...item,
            nestedFilters: [...(item.nestedFilters || []), { id, name: item?.nestedFilters.length > 1 ? item?.nestedFilters[1].name : 'And', nestedFilterOptions: {} }],
          };
        }
        return item;
      });
      return updatedFilters;
    });
  };
  // Add new filter
  const handleAddFilter = () => {
    setFilters((prevFilters) => {
      const newId = prevFilters.length === 0 ? (prevFilters.length + 1).toString() : (parseInt(prevFilters[prevFilters.length - 1]?.id) + 1).toString();
      const newFilter: FilterType = {
        id: newId,
        name: prevFilters.length === 0 ? 'Where' : prevFilters.length > 1 ? prevFilters[1].name : 'And',
        nestedFilters: [{ id: '1', name: 'Where', nestedFilterOptions: {} }],
      };
      return [...prevFilters, newFilter];
    });
  };
  // Delete filter or nested filter
  const handleDeleteFilter = (parentId: string, id: string) => {
    setFilters((prevFilters) => {
      const updatedFilters = prevFilters
        .map((item) => {
          if (item.id === parentId) {
            const updatedNestedFilters = item.nestedFilters?.filter((filter) => filter.id !== id);
            const index = item.nestedFilters?.findIndex((item) => item.id === id);
            if (item?.nestedFilters[index]?.name === 'Where' && updatedNestedFilters.length) {
              updatedNestedFilters[0] = {
                ...updatedNestedFilters[0],
                name: 'Where',
              };
            }
            return {
              ...item,
              nestedFilters: updatedNestedFilters && updatedNestedFilters.length ? updatedNestedFilters : undefined,
            };
          }
          return item;
        })
        .filter((item) => item.nestedFilters !== undefined);
      const parentIndex = prevFilters.findIndex((item) => item.id === parentId);
      if (updatedFilters.length && prevFilters[parentIndex].name === 'Where') {
        updatedFilters[0] = {
          ...updatedFilters[0],
          name: 'Where',
        };
      }
      return updatedFilters;
    });
    setDuplicateData((prev) => ({
      data: 'clear all',
      toggle: !prev.toggle,
    }));
  };
  // Clear filter
  const handleClearFilter = (parentId?: string) => {
    // Clear all filter
    if (!parentId) {
      setDuplicateData((prev) => ({
        data: 'clear all',
        toggle: !prev.toggle,
      }));
      return setFilters([{ id: '1', name: 'Where', nestedFilters: [{ id: '1', name: 'Where', nestedFilterOptions: {} as NestedFilterOptionsType }] }]);
    }
    // Clear each filter
    setFilters((prevFilter) => {
      const updatedFilter = prevFilter.filter((item) => item.id !== parentId);
      if (updatedFilter.length) {
        updatedFilter[0] = {
          ...updatedFilter[0],
          name: 'Where',
        };
      }
      return updatedFilter;
    });
    setDuplicateData((prev) => ({
      data: 'clear all',
      toggle: !prev.toggle,
    }));
  };
  // console.log("filters", filters)
  // Change And and Or condition change operation
  const handleConditionChange = (element: dataType, conditionType?: string, parentId?: string) => {
    setFilters((prevFilters) => {
      return prevFilters?.map((filterItem) => {
        const updatedNestedFilters = filterItem.nestedFilters?.map((nestedFilterItem) => {
          if (conditionType === 'child' && nestedFilterItem.name !== 'Where' && filterItem.id === parentId) return { ...nestedFilterItem, name: element.name };
          return nestedFilterItem;
        });
        return {
          ...filterItem,
          name: filterItem.name !== 'Where' && conditionType === 'parent' ? element.name : filterItem.name,
          nestedFilters: updatedNestedFilters,
        };
      });
    });

    setDuplicateData((prev) => ({
      ...prev,
      toggle: !prev.toggle,
    }));
  };
  // Update the condition when click the option
  const handleChangeMainValue = async (element?: dataType | null, parentId?: string, nestedId?: string, value?: string, event?: any) => {
    // event &&  console.log("event.target.value",parseInt(event.target.value) > 100)
    if (event && parseInt(event.target.value) > 100000) return;
    setFilters((prevFilters) => {
      return prevFilters?.map((filterItem) => {
        if (parentId === filterItem.id) {
          const updatedNestedFilters = filterItem.nestedFilters?.map((nestedFilterItem) => {
            if (nestedFilterItem.id === nestedId) {
              let updatedOptions: any = {};
              if (value !== 'mainValue') {
                updatedOptions = {
                  ...nestedFilterItem.nestedFilterOptions,
                  [value]: element?.name ? { uniqueName: element?.uniqueName, value: element?.name } : { uniqueName: event?.target?.value, value: event?.target?.value },
                };
              } else {
                updatedOptions = {
                  // ...nestedFilterItem.nestedFilterOptions,
                  [value]: element?.name ? { uniqueName: element?.uniqueName, value: element?.name } : { uniqueName: event?.target?.value, value: event?.target?.value },
                };
              }
              if (value === 'mainValue') {
                const condition = optionData.find((item: any) => item?.name === element?.name)?.conditions[0];
                updatedOptions['condition'] = {
                  uniqueName: condition?.uniqueName,
                  value: condition?.name,
                };
                updatedOptions['resultValue'] = { uniqueName: '', value: '' };
              }
              return { ...nestedFilterItem, nestedFilterOptions: updatedOptions };
            }
            return nestedFilterItem;
          });
          return { ...filterItem, nestedFilters: updatedNestedFilters };
        }
        return filterItem;
      });
    });
    if (event) {
      // console.log('event', event?.target?.value)
      return setDebounceState(event?.target?.value);
    }
    setDuplicateData((prev) => ({
      data: value,
      toggle: !prev.toggle,
    }));
    // if (value === 'resultValue') {
    //     setDuplicateData((prev) => ({
    //         data: "resultValue",
    //         toggle: !prev.toggle
    //     }))
    // }
  };

  const filtersCount: number = filters.reduce((acc, item) => {
    const nestedCount = item.nestedFilters.filter((nestedOption) => nestedOption?.nestedFilterOptions?.hasOwnProperty('resultValue')).length;
    return acc + nestedCount;
  }, 0);

  return (
    <CustomMenu
      menuListClassName={`dark:bg-darkThree dark:!border-darkBorder dark:shadow-darkBorder ${position === 'left' ? 'right-0' : 'left-0'}`}
      btnClassName={'dark:text-white dark:!border-darkBorder '}
      btnId={id}
      btnContent={`Filter${filtersCount > 1 ? 's' : ''} ${filtersCount > 0 ? `(${filtersCount})` : ''}`}
      leftIcon={<VscListFilter size={15} className="dark:text-white " />}
    >
      <div className={`${filters.length > 1 ? 'w-[40rem]' : 'w-[35rem]'} mb-1 bg-white duration-300 dark:bg-darkThree`}>
        {/* Header */}
        <div id="filter-header" className="flex items-center justify-between px-2 dark:!text-gray-200 ">
          <h4 className="text-sm font-bold text-brand-900 dark:!text-gray-200">Add Filters</h4>
          <div className="flex gap-3">
            <button id="clear-filter" className="w-fit rounded-md px-3 py-2 text-center text-xs font-medium text-brand-900 duration-300 hover:bg-orange-50 active:scale-95 dark:!text-gray-200" onClick={() => handleClearFilter()}>
              Clear Filter
            </button>
            <button id="add-filter" className="flex items-center justify-center gap-1 rounded-md border-2 border-gray-100 px-2 py-2 text-xs font-medium text-brand-900 duration-200 hover:bg-orange-50 active:scale-95 dark:!text-gray-200" onClick={handleAddFilter}>
              <IoMdAdd size={13} />
              <span>Add filter</span>
            </button>
          </div>
        </div>
        {/* Body */}
        <div>
          {filters?.map((filterItem: FilterType, index: number) => {
            const parentId = filterItem.id;
            return (
              <div className="mx-1 mt-2 flex items-center gap-1" key={parentId}>
                {filters.length > 1 &&
                  (index === 1 ? (
                    <div className="flex w-24 justify-center ">
                      <CustomDropDown
                        btnId={`filter-condition-${index}`}
                        label={filterItem.name}
                        dropDownData={[
                          { id: '1', name: 'And' },
                          { id: '2', name: 'Or' },
                        ]}
                        menuBtnClassName="!text-gray-700 !text-sm !border dark:!text-gray-200 dark:!bg-darkTwo !bg-white !font-normal text-start !h-8 w-full"
                        menuListWidth="w-full"
                        menuClassName="w-20 dark:!text-gray-200 dark:!bg-darkTwo"
                        iconClassName="text-brand-600"
                        elementClassName="text-gray-800 p-2 text-xs duration-300 cursor-pointer hover:bg-gray-50"
                        onClick={(element: dataType) => handleConditionChange(element, 'parent')}
                      />
                    </div>
                  ) : (
                    <div className={`flex w-24 justify-center ${filterItem.name !== 'Where' && 'text-gray-600'}`}>{filterItem.name}</div>
                  ))}
                <div className="group w-full rounded-md bg-[#4318FF0A] p-2 dark:bg-darkTwo dark:!text-gray-200">
                  {filterItem?.nestedFilters?.map((nestedItem: NestedFilterType, index: number) => {
                    const mainValue = nestedItem?.nestedFilterOptions?.mainValue;
                    const options = mainValue?.value ? optionData?.filter((item: any) => item.name === mainValue?.value) : [];
                    const conditionDropDownData = options[0]?.conditions;
                    const resultValueDropDownData = options[0]?.resultValue;
                    const resultValueField = options[0]?.result_value_field_type;
                    return (
                      <div key={index} className="mt-2 flex items-center gap-2">
                        {filterItem.nestedFilters?.length > 1 &&
                          (index === 1 ? (
                            <div className="flex w-24 justify-center">
                              <CustomDropDown
                                btnId={`nested-filter-condition-${parentId}-${index}`}
                                label={nestedItem.name}
                                dropDownData={[
                                  { id: '1', name: 'And' },
                                  { id: '2', name: 'Or' },
                                ]}
                                menuBtnClassName="!text-gray-700 !text-sm dark:!text-gray-200 !border dark:!text-gray-200 dark:bg-darkTwo !bg-white !font-normal text-start !h-8 w-full"
                                menuListWidth="w-full"
                                menuClassName="w-20 dark:!text-gray-200 dark:bg-darkTwo"
                                iconClassName="text-brand-600"
                                elementClassName="text-gray-800 dark:!text-gray-200 dark:bg-darkTwo p-2 text-xs duration-300 cursor-pointer hover:bg-gray-50"
                                onClick={(element: dataType) => handleConditionChange(element, 'child', filterItem.id)}
                              />
                            </div>
                          ) : (
                            <div className={`flex w-24 justify-center text-sm ${nestedItem.name !== 'Where' && 'text-gray-600'}`}>{nestedItem.name}</div>
                          ))}
                        <div className="flex w-full items-center justify-between">
                          <div className="flex w-full gap-1">
                            <CustomDropDown
                              btnId={`campaign-mainValue-${index}`}
                              label={mainValue?.value || 'Select Filter'}
                              dropDownData={dropDownData}
                              menuBtnClassName="!text-gray-700  !text-xs dark:!text-gray-200 !border !bg-white !font-normal text-start !h-8 w-full"
                              menuListWidth="w-full"
                              menuClassName="w-[35%] dark:!text-gray-200 "
                              iconClassName="text-brand-600 dark:!text-gray-200"
                              elementClassName="text-gray-800 p-2  dark:!text-gray-200 text-xs duration-300 cursor-pointer hover:bg-gray-50 dark:hover:bg-darkOne"
                              onClick={(element) => handleChangeMainValue(element, parentId, nestedItem.id, 'mainValue')}
                            />
                            {nestedItem?.nestedFilterOptions?.mainValue && (
                              <>
                                <CustomDropDown
                                  btnId={`campaign-condition-${index}`}
                                  label={nestedItem?.nestedFilterOptions?.condition?.value || 'Select Filter'}
                                  dropDownData={conditionDropDownData}
                                  menuBtnClassName="!text-gray-700 !text-xs !border dark:!text-gray-200 !bg-white !font-normal text-start !h-8 w-full"
                                  menuListWidth="w-full"
                                  menuClassName="min-w-[26%] "
                                  iconClassName="text-brand-600 dark:!text-gray-200"
                                  elementClassName="text-gray-800 p-2 text-xs duration-300 cursor-pointer hover:bg-gray-50 dark:!text-gray-200 dark:hover:bg-darkOne"
                                  onClick={(element) => handleChangeMainValue(element, parentId, nestedItem.id, 'condition')}
                                />
                                {nestedItem?.nestedFilterOptions?.mainValue?.value === 'Open Rate' || nestedItem?.nestedFilterOptions?.mainValue?.value === 'Unsub Rate' || resultValueField === 'input' ? (
                                  <input
                                    id={`openRate-${index}`}
                                    placeholder="Enter percentage"
                                    value={nestedItem?.nestedFilterOptions?.resultValue?.value}
                                    className=" h-8 w-[39%] rounded-md border pl-4 text-xs text-gray-800 caret-gray-700 focus-within:outline-none dark:!text-gray-200"
                                    type="number"
                                    onChange={(event) => handleChangeMainValue(null, parentId, nestedItem.id, 'resultValue', event)}
                                  />
                                ) : resultValueField === 'date' ? (
                                  <CustomDatePicker
                                    customClass={`${filterItem?.nestedFilters?.length > 1 ? 'w-40' : 'w-48'}`}
                                    customSelectedDateClass={`${filterItem?.nestedFilters?.length > 1 ? 'text-[9px]' : 'text-[0.7rem]'}`}
                                    onChange={(date: any) => handleChangeMainValue(null, parentId, nestedItem.id, 'resultValue', { target: { value: `${moment(date[0]).format('YYYY-MM-DD')},${moment(date[1]).format('YYYY-MM-DD')}` } })}
                                  />
                                ) : (
                                  <CustomDropDown
                                    btnId={`campaign-resultValue-${index}`}
                                    label={nestedItem?.nestedFilterOptions?.resultValue?.value || 'Select Filter'}
                                    dropDownData={resultValueDropDownData}
                                    menuBtnClassName="!text-gray-700 !text-xs dark:!text-gray-200 !border !bg-white !font-normal text-start !h-8 w-full"
                                    menuListWidth="w-full"
                                    menuClassName="min-w-[39%]"
                                    iconClassName="text-brand-600 dark:!text-gray-200"
                                    elementClassName="text-gray-800 p-2 text-xs duration-300 cursor-pointer hover:bg-gray-50 dark:!text-gray-200 dark:hover:bg-darkOne"
                                    onClick={(element) => handleChangeMainValue(element, parentId, nestedItem.id, 'resultValue')}
                                  />
                                )}
                              </>
                            )}
                          </div>
                          <button className="ml-1 rounded-md p-2 text-lg text-gray-600  duration-300 hover:bg-red-50  hover:text-red-400 active:scale-90" onClick={() => handleDeleteFilter(parentId, nestedItem.id)}>
                            <RiDeleteBin6Line />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  <div className="mb-1 mt-2 flex justify-between">
                    <button id={`add-nested-filter-${index}`} className="flex w-fit items-center gap-1 rounded-md px-1 text-xs font-semibold text-brand-900 duration-150 active:scale-95 dark:!text-gray-300" onClick={() => handleAddNestedFilter(parentId)}>
                      <span>
                        <IoMdAdd size={15} />
                      </span>
                      <span>Add nested filter</span>
                    </button>
                    {filterItem?.nestedFilters?.length > 1 && (
                      <button className="hidden w-fit rounded-md px-2 text-center text-xs font-bold text-gray-600 duration-150 hover:text-brand-700 active:scale-95 group-hover:block dark:!text-gray-300" onClick={() => handleClearFilter(parentId)}>
                        Clear All
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </CustomMenu>
  );
};

export default CustomFilter;
