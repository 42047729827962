import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { FiSearch } from 'react-icons/fi';
import { useRef } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useDisclosure } from '@chakra-ui/hooks';
import { useAppSelector } from 'redux-store';
import { setGlobalOpt, setTableRowsList } from 'redux-store/custom';
import { BaseApi } from 'api/services/base-api';
import { setAndGetQueryParams } from 'utility/utils';
import CheckBox from 'components/common/MultiSelectCheckBox';
import Button from 'components/common/Button';
import InfiniteScrollComponent from 'components/common/infinityScroll';
import TableLoader from 'components/common/Loaders/TableViewLoader';
import NewMenuModal from '../components/NewMenuModal';
import RowSettings from '../components/MenuRowSettings';
import { DeleteAdminMenuList, FetchAdminMenuList } from 'shared/admin-panel/admin-menu-lists/event-handler/menu-events';

const RoleMenuList = () => {
  const [data, setData] = React.useState({
    data: [],
    count: 0,
  });
  const [editId, setId] = React.useState('');
  const [queryParams, setQueryParams] = React.useState({
    limit: 25,
    offset: 0,
    sort: { columnName: '', order: '' },
    search: '',
    filter: '',
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [columns, setColumns] = React.useState([]);
  const searchRef = useRef(null);
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const custom = useAppSelector((state) => state?.custom);

  const columnHelper = createColumnHelper<any>();

  const table = useReactTable({
    data: data?.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });

  const onSelect = (data: string[]) => {
    dispatch(setTableRowsList(data));
  };
  const deleteList = async (uuid: string) => {
    await DeleteAdminMenuList(fetchList, uuid);
  };
  const columnData = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <div className="ml-5 flex items-center">
          <input type="checkbox" id="select-all" checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} className="block size-3.5 w-full cursor-pointer accent-brand-500 dark:accent-purple-600 " />
        </div>
      ),
      cell: (info) => {
        return (
          <div className="ml-5 flex items-center" onClick={(event) => event?.stopPropagation()}>
            <CheckBox onSelect={onSelect} info={info} table={table} />
          </div>
        );
      },
    }),
    columnHelper.accessor('menu_name', {
      id: 'menu_name',
      enableSorting: false,
      header: () => <div>Menu Name</div>,
      cell: (info) => <h5 className="text-xs">{info.getValue()}</h5>,
    }),
    columnHelper.accessor('menu_type', {
      id: 'menu_type',
      enableSorting: false,
      header: () => <div>Menu Type</div>,
      cell: (info) => <h5 className="w-32 truncate text-xs">{info.getValue()}</h5>,
    }),
    // columnHelper.accessor('price', {
    //     id: 'price',
    //     enableSorting: false,
    //     header: () => <div>Price</div>,
    //     cell: (info) => <h5 className="text-xs">{info.getValue()}</h5>,
    // }),
    // columnHelper.accessor('currency', {
    //     id: 'currency',
    //     header: () => <div>Currency</div>,
    //     cell: (info) => {
    //         return (
    //             <h5 className="truncate text-xs" id={`currenty-${info.row.id}`}>
    //                 {info.getValue()}
    //             </h5>
    //         );
    //     },
    // }),
    columnHelper.accessor('order_index', {
      id: 'order_index',
      header: () => <div>Order Index</div>,

      cell: (info) => {
        return (
          <h5 className="text-xs capitalize" id={`support-${info.row.id}`}>
            {info.getValue()}
          </h5>
        );
      },
    }),

    columnHelper.accessor('options', {
      id: 'options',
      header: () => <div>Options Available</div>,
      cell: (info) => {
        return (
          <h5 className="text-xs capitalize" id={`analytics-${info.row.id}`}>
            {info.getValue()?.length || 0}
          </h5>
        );
      },
    }),
    columnHelper.accessor('uuid', {
      id: 'uuid',
      header: () => <div className="relative w-min font-thin">{''}</div>,
      cell: (row) => {
        return <div className="ml-4">{<RowSettings id={row.row.id} uuidRow={row.getValue()} rowData={row.row.original} table={table} setData={setData} onOpen={onOpen} setId={setId} deleteList={deleteList} />}</div>;
      },
    }),
  ];

  React.useEffect(() => {
    if (table.getSelectedRowModel().rows.length > 1) dispatch(setGlobalOpt(true));
    else dispatch(setGlobalOpt(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel()]);

  const fetchList = async () => {
    await FetchAdminMenuList(setColumns, setQueryParams, setData, data, columnData, table);
  };
  React.useEffect(() => {
    (async () => {
      setTableLoading(true);
      await fetchList();
      setTableLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);
  // Fetch true verifier ticket list when creating new ticket
  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom?.globalDependencyLoading]);

  const scrollFunction = React.useCallback(
    async (setIsFetching: any) => {
      const fetchEntryList = async () => {
        try {
          setIsFetching(true);
          const query = setAndGetQueryParams([
            { key: 'limit', value: queryParams?.limit },
            { key: 'offset', value: queryParams?.offset },
            { key: 'search', value: queryParams?.search },
            {
              key: 'sort',
              value: queryParams?.sort?.columnName ? `${queryParams?.sort?.columnName}%${queryParams?.sort?.order}` : '',
            },
            { key: 'filter', value: queryParams?.filter },
          ]);
          const response = await BaseApi.get(`subscription/plan?${query}`);
          const responseData: any = response.data;
          if (!responseData?.data) return setData((prev) => ({ ...prev, data: [], count: 0 }));
          if (responseData?.data?.length < 25) {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
              count: responseData?.total_records?.count,
            }));
          } else {
            setData((prev) => ({
              ...prev,
              data: [...prev?.data, ...responseData?.data],
            }));
          }
          setQueryParams((prev) => ({
            ...prev,
            offset: prev?.offset + 1,
          }));
        } catch (error) {
          console.log(error);
        } finally {
          setIsFetching(false);
        }
      };

      data?.data?.length && data?.data?.length < data?.count && fetchEntryList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data?.length, queryParams?.filter, queryParams?.sort?.columnName, queryParams?.sort?.order, queryParams?.offset]
  );

  return (
    <>
      <div className={`mt-2.5 w-full rounded-lg bg-white pb-2 pt-2.5 shadow-sm dark:bg-darkThree dark:text-white sm:overflow-x-auto`}>
        <div className="relative mb-3 flex items-center py-0.5 sm:gap-32 md:px-10 lg:gap-32 lg:px-3 xl:justify-between">
          <div className="flex gap-2.5">
            <div
              className="flex items-center gap-2 rounded-md border px-3 py-1.5 outline 
                         outline-0 focus-within:outline-2 focus-within:outline-brand-400 
                        dark:border-darkBorder md:w-[13rem] lg:w-[26rem]"
              onClick={() => searchRef.current.focus()}
            >
              <FiSearch className="size-4 cursor-pointer" />
              <input
                ref={searchRef}
                type="text"
                placeholder="Search"
                id="subscription-search-input"
                className="w-full text-sm font-bold
                             text-gray-800 caret-brand-500 outline-none placeholder:text-xs placeholder:text-gray-700 dark:bg-darkThree dark:text-gray-200 dark:placeholder:text-white "
              />
            </div>
          </div>
          <Button
            name="Add Role Menu"
            onClick={() => {
              setId('');
              onOpen();
            }}
          />
        </div>
        <div className="h-[80vh] overflow-y-auto overflow-x-scroll scroll-smooth xl:overflow-x-hidden">
          {!tableLoading ? (
            <>
              <table className="w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, index, arr) => {
                        return (
                          <th key={header.id} colSpan={header.colSpan} onClick={header.column.getToggleSortingHandler()} className={`cursor-pointer border-y border-gray-200 bg-gray-50 py-4 text-start text-column dark:border-white/10 dark:bg-darkOne`}>
                            <div className="text-[11px]">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: '',
                                desc: '',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y-[1px]">
                  {data?.data?.length ? (
                    table.getRowModel().rows.map((row) => {
                      return (
                        <tr key={row.id} className={`dark:hover:bg-darkOne} cursor-pointer hover:bg-hover`}>
                          {row.getVisibleCells().map((cell, i, arr) => {
                            const RowDataWidth = ['w-[5%]', 'w-[20%]', 'w-[20%]', 'w-[8%]', 'w-[8%]', 'w-[8%]', 'w-[8%]', 'w-[8%]', 'w-[8%]', 'w-[3%]'];
                            return (
                              <td key={cell.id} className={`${RowDataWidth[i]} border-white/0 py-3 text-xs font-semibold text-heading dark:text-white`}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
              <InfiniteScrollComponent loadMore={scrollFunction} loader={<TableLoader width="w-full" border="border border-gray-100" column={6} row={1} skeletonHeight="h-2" padding="px-5 py-4" columnWidth="4% 25% 22% 22% 25% 4%" />} />
            </>
          ) : (
            <TableLoader width="w-full" border="border border-gray-100" column={6} row={13} skeletonHeight="h-2" padding="px-5 py-4" tableViewBg={{ headBg: 'bg-gray-50' }} columnWidth="4% 25% 22% 22% 25% 4%" />
          )}
        </div>
      </div>
      {isOpen && <NewMenuModal isOpen={isOpen} onClose={onClose} uuid={editId} fetchList={fetchList} />}
    </>
  );
};

export default RoleMenuList;
