import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthUserStateDTO } from 'dto/types/redux-store/authentication';

const initialState: AuthUserStateDTO = {
  // loggedUser: null
  loggedUser: {},
  loggedUserRolePermissionList: '',
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setLoggedUserDetails: (state, action: PayloadAction<object>) => {
      state.loggedUser = { ...state?.loggedUser, ...action?.payload };
    },
    setLoggedUserRolePermissionList: (state, action: PayloadAction<string>) => {
      state.loggedUserRolePermissionList = action.payload;
    },
    setAppSettings: (state, action) => {
      const { key, value } = action.payload;
      state.loggedUser.app_settings = { ...state.loggedUser.app_settings, [key]: value };
    },
  },
});

export const { setLoggedUserDetails, setLoggedUserRolePermissionList, setAppSettings } = headerSlice.actions;
export default headerSlice.reducer;
